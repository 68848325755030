h1 {
  color: #71a3d6;
  font-family: 'Nunito Sans', 'Helvetica Neue', sans-serif;
  font-weight: 900;
  font-size: 40px;
  margin-bottom: 10px;
}
p {
  color: #404f5e;
  font-family: 'Nunito Sans', 'Helvetica Neue', sans-serif;
  font-size: 20px;
  margin: 0;
}
i {
  color: #71a3d6;
  font-size: 100px;
  line-height: 200px;
  margin-left: -15px;
}
.card {
  background: white;
  padding: 60px;
  border-radius: 4px;
  box-shadow: 0 2px 3px #c8d0d8;
  display: inline-block;
  margin: 0 auto;
}

.success-text {
  margin-top: 1vh;
  margin-bottom: 2vh;
}

@media screen and (min-width: 800px) {
  .success-text {
    width: 500px;
  }
}
