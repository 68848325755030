@font-face {
  font-family: 'icomoon';
  src: url('../../../public/fonts/icomoon/icomoon.eot');
  src: url('../../../public/fonts//icomoon/icomoon.eot?1oniuf#iefix')
      format('embedded-opentype'),
    url('../../../public/fonts//icomoon/icomoon.ttf?1oniuf') format('truetype'),
    url('../../../public/fonts//icomoon/icomoon.woff?1oniuf') format('woff'),
    url('../../../public/fonts//icomoon/icomoon.svg?1oniuf#icomoon')
      format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-mobile:before {
  content: '\e000';
}
.icon-laptop:before {
  content: '\e001';
}
.icon-desktop:before {
  content: '\e002';
}
.icon-tablet:before {
  content: '\e003';
}
.icon-phone:before {
  content: '\e004';
}
.icon-document:before {
  content: '\e005';
}
.icon-documents:before {
  content: '\e006';
}
.icon-search:before {
  content: '\e007';
}
.icon-clipboard:before {
  content: '\e008';
}
.icon-newspaper:before {
  content: '\e009';
}
.icon-notebook:before {
  content: '\e00a';
}
.icon-book-open:before {
  content: '\e00b';
}
.icon-browser:before {
  content: '\e00c';
}
.icon-calendar:before {
  content: '\e00d';
}
.icon-presentation:before {
  content: '\e00e';
}
.icon-picture:before {
  content: '\e00f';
}
.icon-pictures:before {
  content: '\e010';
}
.icon-video:before {
  content: '\e011';
}
.icon-camera:before {
  content: '\e012';
}
.icon-printer:before {
  content: '\e013';
}
.icon-toolbox:before {
  content: '\e014';
}
.icon-briefcase:before {
  content: '\e015';
}
.icon-wallet:before {
  content: '\e016';
}
.icon-gift:before {
  content: '\e017';
}
.icon-bargraph:before {
  content: '\e018';
}
.icon-grid:before {
  content: '\e019';
}
.icon-expand:before {
  content: '\e01a';
}
.icon-focus:before {
  content: '\e01b';
}
.icon-edit:before {
  content: '\e01c';
}
.icon-adjustments:before {
  content: '\e01d';
}
.icon-ribbon:before {
  content: '\e01e';
}
.icon-hourglass:before {
  content: '\e01f';
}
.icon-lock:before {
  content: '\e020';
}
.icon-megaphone:before {
  content: '\e021';
}
.icon-shield:before {
  content: '\e022';
}
.icon-trophy:before {
  content: '\e023';
}
.icon-flag:before {
  content: '\e024';
}
.icon-map:before {
  content: '\e025';
}
.icon-puzzle:before {
  content: '\e026';
}
.icon-basket:before {
  content: '\e027';
}
.icon-envelope:before {
  content: '\e028';
}
.icon-streetsign:before {
  content: '\e029';
}
.icon-telescope:before {
  content: '\e02a';
}
.icon-gears:before {
  content: '\e02b';
}
.icon-key:before {
  content: '\e02c';
}
.icon-paperclip:before {
  content: '\e02d';
}
.icon-attachment:before {
  content: '\e02e';
}
.icon-pricetags:before {
  content: '\e02f';
}
.icon-lightbulb:before {
  content: '\e030';
}
.icon-layers:before {
  content: '\e031';
}
.icon-pencil:before {
  content: '\e032';
}
.icon-tools:before {
  content: '\e033';
}
.icon-tools-2:before {
  content: '\e034';
}
.icon-scissors:before {
  content: '\e035';
}
.icon-paintbrush:before {
  content: '\e036';
}
.icon-magnifying-glass:before {
  content: '\e037';
}
.icon-circle-compass:before {
  content: '\e038';
}
.icon-linegraph:before {
  content: '\e039';
}
.icon-mic:before {
  content: '\e03a';
}
.icon-strategy:before {
  content: '\e03b';
}
.icon-beaker:before {
  content: '\e03c';
}
.icon-caution:before {
  content: '\e03d';
}
.icon-recycle:before {
  content: '\e03e';
}
.icon-anchor:before {
  content: '\e03f';
}
.icon-profile-male:before {
  content: '\e040';
}
.icon-profile-female:before {
  content: '\e041';
}
.icon-bike:before {
  content: '\e042';
}
.icon-wine:before {
  content: '\e043';
}
.icon-hotairballoon:before {
  content: '\e044';
}
.icon-globe:before {
  content: '\e045';
}
.icon-genius:before {
  content: '\e046';
}
.icon-map-pin:before {
  content: '\e047';
}
.icon-dial:before {
  content: '\e048';
}
.icon-chat:before {
  content: '\e049';
}
.icon-heart:before {
  content: '\e04a';
}
.icon-cloud:before {
  content: '\e04b';
}
.icon-upload:before {
  content: '\e04c';
}
.icon-download:before {
  content: '\e04d';
}
.icon-target:before {
  content: '\e04e';
}
.icon-hazardous:before {
  content: '\e04f';
}
.icon-piechart:before {
  content: '\e050';
}
.icon-speedometer:before {
  content: '\e051';
}
.icon-global:before {
  content: '\e052';
}
.icon-compass:before {
  content: '\e053';
}
.icon-lifesaver:before {
  content: '\e054';
}
.icon-clock:before {
  content: '\e055';
}
.icon-aperture:before {
  content: '\e056';
}
.icon-quote:before {
  content: '\e057';
}
.icon-scope:before {
  content: '\e058';
}
.icon-alarmclock:before {
  content: '\e059';
}
.icon-refresh:before {
  content: '\e05a';
}
.icon-happy:before {
  content: '\e05b';
}
.icon-sad:before {
  content: '\e05c';
}
.icon-facebook:before {
  content: '\e05d';
}
.icon-twitter:before {
  content: '\e05e';
}
.icon-googleplus:before {
  content: '\e05f';
}
.icon-rss:before {
  content: '\e060';
}
.icon-tumblr:before {
  content: '\e061';
}
.icon-linkedin:before {
  content: '\e062';
}
.icon-dribbble:before {
  content: '\e063';
}
.icon-box2:before {
  content: '\ea82';
}
.icon-write:before {
  content: '\ea83';
}
.icon-clock2:before {
  content: '\ea84';
}
.icon-reply2:before {
  content: '\ea85';
}
.icon-reply-all2:before {
  content: '\ea86';
}
.icon-forward2:before {
  content: '\ea87';
}
.icon-flag2:before {
  content: '\ea88';
}
.icon-search2:before {
  content: '\ea89';
}
.icon-trash2:before {
  content: '\ea8a';
}
.icon-envelope2:before {
  content: '\ea8b';
}
.icon-bubble:before {
  content: '\ea8c';
}
.icon-bubbles:before {
  content: '\ea8d';
}
.icon-user2:before {
  content: '\ea8e';
}
.icon-users2:before {
  content: '\ea8f';
}
.icon-cloud2:before {
  content: '\ea90';
}
.icon-download2:before {
  content: '\ea91';
}
.icon-upload2:before {
  content: '\ea92';
}
.icon-rain:before {
  content: '\ea93';
}
.icon-sun:before {
  content: '\ea94';
}
.icon-moon2:before {
  content: '\ea95';
}
.icon-bell2:before {
  content: '\ea96';
}
.icon-folder2:before {
  content: '\ea97';
}
.icon-pin2:before {
  content: '\ea98';
}
.icon-sound2:before {
  content: '\ea99';
}
.icon-microphone:before {
  content: '\ea9a';
}
.icon-camera2:before {
  content: '\ea9b';
}
.icon-image2:before {
  content: '\ea9c';
}
.icon-cog2:before {
  content: '\ea9d';
}
.icon-calendar2:before {
  content: '\ea9e';
}
.icon-book2:before {
  content: '\ea9f';
}
.icon-map-marker:before {
  content: '\eaa0';
}
.icon-store:before {
  content: '\eaa1';
}
.icon-support:before {
  content: '\eaa2';
}
.icon-tag2:before {
  content: '\eaa3';
}
.icon-heart2:before {
  content: '\eaa4';
}
.icon-video-camera:before {
  content: '\eaa5';
}
.icon-trophy2:before {
  content: '\eaa6';
}
.icon-cart:before {
  content: '\eaa7';
}
.icon-eye2:before {
  content: '\eaa8';
}
.icon-cancel:before {
  content: '\eaa9';
}
.icon-chart:before {
  content: '\eaaa';
}
.icon-target2:before {
  content: '\eaab';
}
.icon-printer2:before {
  content: '\eaac';
}
.icon-location2:before {
  content: '\eaad';
}
.icon-bookmark2:before {
  content: '\eaae';
}
.icon-monitor:before {
  content: '\eaaf';
}
.icon-cross2:before {
  content: '\eab0';
}
.icon-plus2:before {
  content: '\eab1';
}
.icon-left:before {
  content: '\eab2';
}
.icon-up:before {
  content: '\eab3';
}
.icon-browser2:before {
  content: '\eab4';
}
.icon-windows:before {
  content: '\eab5';
}
.icon-switch2:before {
  content: '\eab6';
}
.icon-dashboard:before {
  content: '\eab7';
}
.icon-play:before {
  content: '\eab8';
}
.icon-fast-forward:before {
  content: '\eab9';
}
.icon-next:before {
  content: '\eaba';
}
.icon-refresh2:before {
  content: '\eabb';
}
.icon-film:before {
  content: '\eabc';
}
.icon-home2:before {
  content: '\eabd';
}
.icon-add-to-list:before {
  content: '\e901';
}
.icon-classic-computer:before {
  content: '\e902';
}
.icon-controller-fast-backward:before {
  content: '\e903';
}
.icon-creative-commons-attribution:before {
  content: '\e904';
}
.icon-creative-commons-noderivs:before {
  content: '\e905';
}
.icon-creative-commons-noncommercial-eu:before {
  content: '\e906';
}
.icon-creative-commons-noncommercial-us:before {
  content: '\e907';
}
.icon-creative-commons-public-domain:before {
  content: '\e908';
}
.icon-creative-commons-remix:before {
  content: '\e909';
}
.icon-creative-commons-share:before {
  content: '\e90a';
}
.icon-creative-commons-sharealike:before {
  content: '\e90b';
}
.icon-creative-commons:before {
  content: '\e90c';
}
.icon-document-landscape:before {
  content: '\e90d';
}
.icon-remove-user:before {
  content: '\e90e';
}
.icon-warning:before {
  content: '\e90f';
}
.icon-arrow-bold-down:before {
  content: '\e910';
}
.icon-arrow-bold-left:before {
  content: '\e911';
}
.icon-arrow-bold-right:before {
  content: '\e912';
}
.icon-arrow-bold-up:before {
  content: '\e913';
}
.icon-arrow-down:before {
  content: '\e914';
}
.icon-arrow-left:before {
  content: '\e915';
}
.icon-arrow-long-down:before {
  content: '\e916';
}
.icon-arrow-long-left:before {
  content: '\e917';
}
.icon-arrow-long-right:before {
  content: '\e918';
}
.icon-arrow-long-up:before {
  content: '\e919';
}
.icon-arrow-right:before {
  content: '\e91a';
}
.icon-arrow-up:before {
  content: '\e91b';
}
.icon-arrow-with-circle-down:before {
  content: '\e91c';
}
.icon-arrow-with-circle-left:before {
  content: '\e91d';
}
.icon-arrow-with-circle-right:before {
  content: '\e91e';
}
.icon-arrow-with-circle-up:before {
  content: '\e91f';
}
.icon-bookmark:before {
  content: '\e920';
}
.icon-bookmarks:before {
  content: '\e921';
}
.icon-chevron-down:before {
  content: '\e922';
}
.icon-chevron-left:before {
  content: '\e923';
}
.icon-chevron-right:before {
  content: '\e924';
}
.icon-chevron-small-down:before {
  content: '\e925';
}
.icon-chevron-small-left:before {
  content: '\e926';
}
.icon-chevron-small-right:before {
  content: '\e927';
}
.icon-chevron-small-up:before {
  content: '\e928';
}
.icon-chevron-thin-down:before {
  content: '\e929';
}
.icon-chevron-thin-left:before {
  content: '\e92a';
}
.icon-chevron-thin-right:before {
  content: '\e92b';
}
.icon-chevron-thin-up:before {
  content: '\e92c';
}
.icon-chevron-up:before {
  content: '\e92d';
}
.icon-chevron-with-circle-down:before {
  content: '\e92e';
}
.icon-chevron-with-circle-left:before {
  content: '\e92f';
}
.icon-chevron-with-circle-right:before {
  content: '\e930';
}
.icon-chevron-with-circle-up:before {
  content: '\e931';
}
.icon-cloud3:before {
  content: '\e932';
}
.icon-controller-fast-forward:before {
  content: '\e933';
}
.icon-controller-jump-to-start:before {
  content: '\e934';
}
.icon-controller-next:before {
  content: '\e935';
}
.icon-controller-paus:before {
  content: '\e936';
}
.icon-controller-play:before {
  content: '\e937';
}
.icon-controller-record:before {
  content: '\e938';
}
.icon-controller-stop:before {
  content: '\e939';
}
.icon-controller-volume:before {
  content: '\e93a';
}
.icon-dot-single:before {
  content: '\e93b';
}
.icon-dots-three-horizontal:before {
  content: '\e93c';
}
.icon-dots-three-vertical:before {
  content: '\e93d';
}
.icon-dots-two-horizontal:before {
  content: '\e93e';
}
.icon-dots-two-vertical:before {
  content: '\e93f';
}
.icon-download3:before {
  content: '\e940';
}
.icon-emoji-flirt:before {
  content: '\e941';
}
.icon-flow-branch:before {
  content: '\e942';
}
.icon-flow-cascade:before {
  content: '\e943';
}
.icon-flow-line:before {
  content: '\e944';
}
.icon-flow-parallel:before {
  content: '\e945';
}
.icon-flow-tree:before {
  content: '\e946';
}
.icon-install:before {
  content: '\e947';
}
.icon-layers2:before {
  content: '\e948';
}
.icon-open-book:before {
  content: '\e949';
}
.icon-resize-100:before {
  content: '\e94a';
}
.icon-resize-full-screen:before {
  content: '\e94b';
}
.icon-save:before {
  content: '\e94c';
}
.icon-select-arrows:before {
  content: '\e94d';
}
.icon-sound-mute:before {
  content: '\e94e';
}
.icon-sound:before {
  content: '\e94f';
}
.icon-trash:before {
  content: '\e950';
}
.icon-triangle-down:before {
  content: '\e951';
}
.icon-triangle-left:before {
  content: '\e952';
}
.icon-triangle-right:before {
  content: '\e953';
}
.icon-triangle-up:before {
  content: '\e954';
}
.icon-uninstall:before {
  content: '\e955';
}
.icon-upload-to-cloud:before {
  content: '\e956';
}
.icon-upload3:before {
  content: '\e957';
}
.icon-add-user:before {
  content: '\e958';
}
.icon-address:before {
  content: '\e959';
}
.icon-adjust:before {
  content: '\e95a';
}
.icon-air:before {
  content: '\e95b';
}
.icon-aircraft-landing:before {
  content: '\e95c';
}
.icon-aircraft-take-off:before {
  content: '\e95d';
}
.icon-aircraft:before {
  content: '\e95e';
}
.icon-align-bottom:before {
  content: '\e95f';
}
.icon-align-horizontal-middle:before {
  content: '\e960';
}
.icon-align-left:before {
  content: '\e961';
}
.icon-align-right:before {
  content: '\e962';
}
.icon-align-top:before {
  content: '\e963';
}
.icon-align-vertical-middle:before {
  content: '\e964';
}
.icon-archive:before {
  content: '\e965';
}
.icon-area-graph:before {
  content: '\e966';
}
.icon-attachment2:before {
  content: '\e967';
}
.icon-awareness-ribbon:before {
  content: '\e968';
}
.icon-back-in-time:before {
  content: '\e969';
}
.icon-back:before {
  content: '\e96a';
}
.icon-bar-graph:before {
  content: '\e96b';
}
.icon-battery:before {
  content: '\e96c';
}
.icon-beamed-note:before {
  content: '\e96d';
}
.icon-bell:before {
  content: '\e96e';
}
.icon-blackboard:before {
  content: '\e96f';
}
.icon-block:before {
  content: '\e970';
}
.icon-book:before {
  content: '\e971';
}
.icon-bowl:before {
  content: '\e972';
}
.icon-box:before {
  content: '\e973';
}
.icon-briefcase2:before {
  content: '\e974';
}
.icon-browser3:before {
  content: '\e975';
}
.icon-brush:before {
  content: '\e976';
}
.icon-bucket:before {
  content: '\e977';
}
.icon-cake:before {
  content: '\e978';
}
.icon-calculator:before {
  content: '\e979';
}
.icon-calendar3:before {
  content: '\e97a';
}
.icon-camera3:before {
  content: '\e97b';
}
.icon-ccw:before {
  content: '\e97c';
}
.icon-chat2:before {
  content: '\e97d';
}
.icon-check:before {
  content: '\e97e';
}
.icon-circle-with-cross:before {
  content: '\e97f';
}
.icon-circle-with-minus:before {
  content: '\e980';
}
.icon-circle-with-plus:before {
  content: '\e981';
}
.icon-circle:before {
  content: '\e982';
}
.icon-circular-graph:before {
  content: '\e983';
}
.icon-clapperboard:before {
  content: '\e984';
}
.icon-clipboard2:before {
  content: '\e985';
}
.icon-clock3:before {
  content: '\e986';
}
.icon-code:before {
  content: '\e987';
}
.icon-cog:before {
  content: '\e988';
}
.icon-colours:before {
  content: '\e989';
}
.icon-compass2:before {
  content: '\e98a';
}
.icon-copy:before {
  content: '\e98b';
}
.icon-credit-card:before {
  content: '\e98c';
}
.icon-credit:before {
  content: '\e98d';
}
.icon-cross:before {
  content: '\e98e';
}
.icon-cup:before {
  content: '\e98f';
}
.icon-cw:before {
  content: '\e990';
}
.icon-cycle:before {
  content: '\e991';
}
.icon-database:before {
  content: '\e992';
}
.icon-dial-pad:before {
  content: '\e993';
}
.icon-direction:before {
  content: '\e994';
}
.icon-document2:before {
  content: '\e995';
}
.icon-documents2:before {
  content: '\e996';
}
.icon-drink:before {
  content: '\e997';
}
.icon-drive:before {
  content: '\e998';
}
.icon-drop:before {
  content: '\e999';
}
.icon-edit2:before {
  content: '\e99a';
}
.icon-email:before {
  content: '\e99b';
}
.icon-emoji-happy:before {
  content: '\e99c';
}
.icon-emoji-neutral:before {
  content: '\e99d';
}
.icon-emoji-sad:before {
  content: '\e99e';
}
.icon-erase:before {
  content: '\e99f';
}
.icon-eraser:before {
  content: '\e9a0';
}
.icon-export:before {
  content: '\e9a1';
}
.icon-eye:before {
  content: '\e9a2';
}
.icon-feather:before {
  content: '\e9a3';
}
.icon-flag3:before {
  content: '\e9a4';
}
.icon-flash:before {
  content: '\e9a5';
}
.icon-flashlight:before {
  content: '\e9a6';
}
.icon-flat-brush:before {
  content: '\e9a7';
}
.icon-folder-images:before {
  content: '\e9a8';
}
.icon-folder-music:before {
  content: '\e9a9';
}
.icon-folder-video:before {
  content: '\e9aa';
}
.icon-folder:before {
  content: '\e9ab';
}
.icon-forward:before {
  content: '\e9ac';
}
.icon-funnel:before {
  content: '\e9ad';
}
.icon-game-controller:before {
  content: '\e9ae';
}
.icon-gauge:before {
  content: '\e9af';
}
.icon-globe2:before {
  content: '\e9b0';
}
.icon-graduation-cap:before {
  content: '\e9b1';
}
.icon-grid2:before {
  content: '\e9b2';
}
.icon-hair-cross:before {
  content: '\e9b3';
}
.icon-hand:before {
  content: '\e9b4';
}
.icon-heart-outlined:before {
  content: '\e9b5';
}
.icon-heart3:before {
  content: '\e9b6';
}
.icon-help-with-circle:before {
  content: '\e9b7';
}
.icon-help:before {
  content: '\e9b8';
}
.icon-home:before {
  content: '\e9b9';
}
.icon-hour-glass:before {
  content: '\e9ba';
}
.icon-image-inverted:before {
  content: '\e9bb';
}
.icon-image:before {
  content: '\e9bc';
}
.icon-images:before {
  content: '\e9bd';
}
.icon-inbox:before {
  content: '\e9be';
}
.icon-infinity:before {
  content: '\e9bf';
}
.icon-info-with-circle:before {
  content: '\e9c0';
}
.icon-info:before {
  content: '\e9c1';
}
.icon-key2:before {
  content: '\e9c2';
}
.icon-keyboard:before {
  content: '\e9c3';
}
.icon-lab-flask:before {
  content: '\e9c4';
}
.icon-landline:before {
  content: '\e9c5';
}
.icon-language:before {
  content: '\e9c6';
}
.icon-laptop2:before {
  content: '\e9c7';
}
.icon-leaf:before {
  content: '\e9c8';
}
.icon-level-down:before {
  content: '\e9c9';
}
.icon-level-up:before {
  content: '\e9ca';
}
.icon-lifebuoy:before {
  content: '\e9cb';
}
.icon-light-bulb:before {
  content: '\e9cc';
}
.icon-light-down:before {
  content: '\e9cd';
}
.icon-light-up:before {
  content: '\e9ce';
}
.icon-line-graph:before {
  content: '\e9cf';
}
.icon-link:before {
  content: '\e9d0';
}
.icon-list:before {
  content: '\e9d1';
}
.icon-location-pin:before {
  content: '\e9d2';
}
.icon-location:before {
  content: '\e9d3';
}
.icon-lock-open:before {
  content: '\e9d4';
}
.icon-lock2:before {
  content: '\e9d5';
}
.icon-log-out:before {
  content: '\e9d6';
}
.icon-login:before {
  content: '\e9d7';
}
.icon-loop:before {
  content: '\e9d8';
}
.icon-magnet:before {
  content: '\e9d9';
}
.icon-magnifying-glass2:before {
  content: '\e9da';
}
.icon-mail:before {
  content: '\e9db';
}
.icon-man:before {
  content: '\e9dc';
}
.icon-map2:before {
  content: '\e9dd';
}
.icon-mask:before {
  content: '\e9de';
}
.icon-medal:before {
  content: '\e9df';
}
.icon-megaphone2:before {
  content: '\e9e0';
}
.icon-menu:before {
  content: '\e9e1';
}
.icon-message:before {
  content: '\e9e2';
}
.icon-mic2:before {
  content: '\e9e3';
}
.icon-minus:before {
  content: '\e9e4';
}
.icon-mobile2:before {
  content: '\e9e5';
}
.icon-modern-mic:before {
  content: '\e9e6';
}
.icon-moon:before {
  content: '\e9e7';
}
.icon-mouse:before {
  content: '\e9e8';
}
.icon-music:before {
  content: '\e9e9';
}
.icon-network:before {
  content: '\e9ea';
}
.icon-new-message:before {
  content: '\e9eb';
}
.icon-new:before {
  content: '\e9ec';
}
.icon-news:before {
  content: '\e9ed';
}
.icon-note:before {
  content: '\e9ee';
}
.icon-notification:before {
  content: '\e9ef';
}
.icon-old-mobile:before {
  content: '\e9f0';
}
.icon-old-phone:before {
  content: '\e9f1';
}
.icon-palette:before {
  content: '\e9f2';
}
.icon-paper-plane:before {
  content: '\e9f3';
}
.icon-pencil2:before {
  content: '\e9f4';
}
.icon-phone2:before {
  content: '\e9f5';
}
.icon-pie-chart:before {
  content: '\e9f6';
}
.icon-pin:before {
  content: '\e9f7';
}
.icon-plus:before {
  content: '\e9f8';
}
.icon-popup:before {
  content: '\e9f9';
}
.icon-power-plug:before {
  content: '\e9fa';
}
.icon-price-ribbon:before {
  content: '\e9fb';
}
.icon-price-tag:before {
  content: '\e9fc';
}
.icon-print:before {
  content: '\e9fd';
}
.icon-progress-empty:before {
  content: '\e9fe';
}
.icon-progress-full:before {
  content: '\e9ff';
}
.icon-progress-one:before {
  content: '\ea00';
}
.icon-progress-two:before {
  content: '\ea01';
}
.icon-publish:before {
  content: '\ea02';
}
.icon-quote2:before {
  content: '\ea03';
}
.icon-radio:before {
  content: '\ea04';
}
.icon-reply-all:before {
  content: '\ea05';
}
.icon-reply:before {
  content: '\ea06';
}
.icon-retweet:before {
  content: '\ea07';
}
.icon-rocket:before {
  content: '\ea08';
}
.icon-round-brush:before {
  content: '\ea09';
}
.icon-rss2:before {
  content: '\ea0a';
}
.icon-ruler:before {
  content: '\ea0b';
}
.icon-scissors2:before {
  content: '\ea0c';
}
.icon-share-alternitive:before {
  content: '\ea0d';
}
.icon-share:before {
  content: '\ea0e';
}
.icon-shareable:before {
  content: '\ea0f';
}
.icon-shield2:before {
  content: '\ea10';
}
.icon-shop:before {
  content: '\ea11';
}
.icon-shopping-bag:before {
  content: '\ea12';
}
.icon-shopping-basket:before {
  content: '\ea13';
}
.icon-shopping-cart:before {
  content: '\ea14';
}
.icon-shuffle:before {
  content: '\ea15';
}
.icon-signal:before {
  content: '\ea16';
}
.icon-sound-mix:before {
  content: '\ea17';
}
.icon-sports-club:before {
  content: '\ea18';
}
.icon-spreadsheet:before {
  content: '\ea19';
}
.icon-squared-cross:before {
  content: '\ea1a';
}
.icon-squared-minus:before {
  content: '\ea1b';
}
.icon-squared-plus:before {
  content: '\ea1c';
}
.icon-star-outlined:before {
  content: '\ea1d';
}
.icon-star:before {
  content: '\ea1e';
}
.icon-stopwatch:before {
  content: '\ea1f';
}
.icon-suitcase:before {
  content: '\ea20';
}
.icon-swap:before {
  content: '\ea21';
}
.icon-sweden:before {
  content: '\ea22';
}
.icon-switch:before {
  content: '\ea23';
}
.icon-tablet2:before {
  content: '\ea24';
}
.icon-tag:before {
  content: '\ea25';
}
.icon-text-document-inverted:before {
  content: '\ea26';
}
.icon-text-document:before {
  content: '\ea27';
}
.icon-text:before {
  content: '\ea28';
}
.icon-thermometer:before {
  content: '\ea29';
}
.icon-thumbs-down:before {
  content: '\ea2a';
}
.icon-thumbs-up:before {
  content: '\ea2b';
}
.icon-thunder-cloud:before {
  content: '\ea2c';
}
.icon-ticket:before {
  content: '\ea2d';
}
.icon-time-slot:before {
  content: '\ea2e';
}
.icon-tools2:before {
  content: '\ea2f';
}
.icon-traffic-cone:before {
  content: '\ea30';
}
.icon-tree:before {
  content: '\ea31';
}
.icon-trophy3:before {
  content: '\ea32';
}
.icon-tv:before {
  content: '\ea33';
}
.icon-typing:before {
  content: '\ea34';
}
.icon-unread:before {
  content: '\ea35';
}
.icon-untag:before {
  content: '\ea36';
}
.icon-user:before {
  content: '\ea37';
}
.icon-users:before {
  content: '\ea38';
}
.icon-v-card:before {
  content: '\ea39';
}
.icon-video2:before {
  content: '\ea3a';
}
.icon-vinyl:before {
  content: '\ea3b';
}
.icon-voicemail:before {
  content: '\ea3c';
}
.icon-wallet2:before {
  content: '\ea3d';
}
.icon-water:before {
  content: '\ea3e';
}
.icon-500px-with-circle:before {
  content: '\ea3f';
}
.icon-500px:before {
  content: '\ea40';
}
.icon-basecamp:before {
  content: '\ea41';
}
.icon-behance:before {
  content: '\ea42';
}
.icon-creative-cloud:before {
  content: '\ea43';
}
.icon-dropbox:before {
  content: '\ea44';
}
.icon-evernote:before {
  content: '\ea45';
}
.icon-flattr:before {
  content: '\ea46';
}
.icon-foursquare:before {
  content: '\ea47';
}
.icon-google-drive:before {
  content: '\ea48';
}
.icon-google-hangouts:before {
  content: '\ea49';
}
.icon-grooveshark:before {
  content: '\ea4a';
}
.icon-icloud:before {
  content: '\ea4b';
}
.icon-mixi:before {
  content: '\ea4c';
}
.icon-onedrive:before {
  content: '\ea4d';
}
.icon-paypal:before {
  content: '\ea4e';
}
.icon-picasa:before {
  content: '\ea4f';
}
.icon-qq:before {
  content: '\ea50';
}
.icon-rdio-with-circle:before {
  content: '\ea51';
}
.icon-renren:before {
  content: '\ea52';
}
.icon-scribd:before {
  content: '\ea53';
}
.icon-sina-weibo:before {
  content: '\ea54';
}
.icon-skype-with-circle:before {
  content: '\ea55';
}
.icon-skype:before {
  content: '\ea56';
}
.icon-slideshare:before {
  content: '\ea57';
}
.icon-smashing:before {
  content: '\ea58';
}
.icon-soundcloud:before {
  content: '\ea59';
}
.icon-spotify-with-circle:before {
  content: '\ea5a';
}
.icon-spotify:before {
  content: '\ea5b';
}
.icon-swarm:before {
  content: '\ea5c';
}
.icon-vine-with-circle:before {
  content: '\ea5d';
}
.icon-vine:before {
  content: '\ea5e';
}
.icon-vk-alternitive:before {
  content: '\ea5f';
}
.icon-vk-with-circle:before {
  content: '\ea60';
}
.icon-vk:before {
  content: '\ea61';
}
.icon-xing-with-circle:before {
  content: '\ea62';
}
.icon-xing:before {
  content: '\ea63';
}
.icon-yelp:before {
  content: '\ea64';
}
.icon-dribbble-with-circle:before {
  content: '\ea65';
}
.icon-dribbble2:before {
  content: '\ea66';
}
.icon-facebook-with-circle:before {
  content: '\ea67';
}
.icon-facebook2:before {
  content: '\ea68';
}
.icon-flickr-with-circle:before {
  content: '\ea69';
}
.icon-flickr:before {
  content: '\ea6a';
}
.icon-github-with-circle:before {
  content: '\ea6b';
}
.icon-github:before {
  content: '\ea6c';
}
.icon-google-with-circle:before {
  content: '\ea6d';
}
.icon-google:before {
  content: '\ea6e';
}
.icon-instagram-with-circle:before {
  content: '\ea6f';
}
.icon-instagram:before {
  content: '\ea70';
}
.icon-lastfm-with-circle:before {
  content: '\ea71';
}
.icon-lastfm:before {
  content: '\ea72';
}
.icon-linkedin-with-circle:before {
  content: '\ea73';
}
.icon-linkedin2:before {
  content: '\ea74';
}
.icon-pinterest-with-circle:before {
  content: '\ea75';
}
.icon-pinterest:before {
  content: '\ea76';
}
.icon-rdio:before {
  content: '\ea77';
}
.icon-stumbleupon-with-circle:before {
  content: '\ea78';
}
.icon-stumbleupon:before {
  content: '\ea79';
}
.icon-tumblr-with-circle:before {
  content: '\ea7a';
}
.icon-tumblr2:before {
  content: '\ea7b';
}
.icon-twitter-with-circle:before {
  content: '\ea7c';
}
.icon-twitter2:before {
  content: '\ea7d';
}
.icon-vimeo-with-circle:before {
  content: '\ea7e';
}
.icon-vimeo:before {
  content: '\ea7f';
}
.icon-youtube-with-circle:before {
  content: '\ea80';
}
.icon-youtube:before {
  content: '\ea81';
}
.icon-home3:before {
  content: '\eabe';
}
.icon-home22:before {
  content: '\eabf';
}
.icon-home32:before {
  content: '\eac0';
}
.icon-office:before {
  content: '\eac1';
}
.icon-newspaper2:before {
  content: '\eac2';
}
.icon-pencil3:before {
  content: '\eac3';
}
.icon-pencil22:before {
  content: '\eac4';
}
.icon-quill:before {
  content: '\eac5';
}
.icon-pen:before {
  content: '\eac6';
}
.icon-blog:before {
  content: '\eac7';
}
.icon-eyedropper:before {
  content: '\eac8';
}
.icon-droplet:before {
  content: '\eac9';
}
.icon-paint-format:before {
  content: '\eaca';
}
.icon-image3:before {
  content: '\eacb';
}
.icon-images2:before {
  content: '\eacc';
}
.icon-camera4:before {
  content: '\eacd';
}
.icon-headphones:before {
  content: '\eace';
}
.icon-music2:before {
  content: '\eacf';
}
.icon-play2:before {
  content: '\ead0';
}
.icon-film2:before {
  content: '\ead1';
}
.icon-video-camera2:before {
  content: '\ead2';
}
.icon-dice:before {
  content: '\ead3';
}
.icon-pacman:before {
  content: '\ead4';
}
.icon-spades:before {
  content: '\ead5';
}
.icon-clubs:before {
  content: '\ead6';
}
.icon-diamonds:before {
  content: '\ead7';
}
.icon-bullhorn:before {
  content: '\ead8';
}
.icon-connection:before {
  content: '\ead9';
}
.icon-podcast:before {
  content: '\eada';
}
.icon-feed:before {
  content: '\eadb';
}
.icon-mic3:before {
  content: '\eadc';
}
.icon-book3:before {
  content: '\eadd';
}
.icon-books:before {
  content: '\eade';
}
.icon-library:before {
  content: '\eadf';
}
.icon-file-text:before {
  content: '\eae0';
}
.icon-profile:before {
  content: '\eae1';
}
.icon-file-empty:before {
  content: '\eae2';
}
.icon-files-empty:before {
  content: '\eae3';
}
.icon-file-text2:before {
  content: '\eae4';
}
.icon-file-picture:before {
  content: '\eae5';
}
.icon-file-music:before {
  content: '\eae6';
}
.icon-file-play:before {
  content: '\eae7';
}
.icon-file-video:before {
  content: '\eae8';
}
.icon-file-zip:before {
  content: '\eae9';
}
.icon-copy2:before {
  content: '\eaea';
}
.icon-paste:before {
  content: '\eaeb';
}
.icon-stack:before {
  content: '\eaec';
}
.icon-folder3:before {
  content: '\eaed';
}
.icon-folder-open:before {
  content: '\eaee';
}
.icon-folder-plus:before {
  content: '\eaef';
}
.icon-folder-minus:before {
  content: '\eaf0';
}
.icon-folder-download:before {
  content: '\eaf1';
}
.icon-folder-upload:before {
  content: '\eaf2';
}
.icon-price-tag2:before {
  content: '\eaf3';
}
.icon-price-tags:before {
  content: '\eaf4';
}
.icon-barcode:before {
  content: '\eaf5';
}
.icon-qrcode:before {
  content: '\eaf6';
}
.icon-ticket2:before {
  content: '\eaf7';
}
.icon-cart2:before {
  content: '\eaf8';
}
.icon-coin-dollar:before {
  content: '\eaf9';
}
.icon-coin-euro:before {
  content: '\eafa';
}
.icon-coin-pound:before {
  content: '\eafb';
}
.icon-coin-yen:before {
  content: '\eafc';
}
.icon-credit-card2:before {
  content: '\eafd';
}
.icon-calculator2:before {
  content: '\eafe';
}
.icon-lifebuoy2:before {
  content: '\eaff';
}
.icon-phone3:before {
  content: '\eb00';
}
.icon-phone-hang-up:before {
  content: '\eb01';
}
.icon-address-book:before {
  content: '\eb02';
}
.icon-envelop:before {
  content: '\eb03';
}
.icon-pushpin:before {
  content: '\eb04';
}
.icon-location3:before {
  content: '\eb05';
}
.icon-location22:before {
  content: '\eb06';
}
.icon-compass3:before {
  content: '\eb07';
}
.icon-compass22:before {
  content: '\eb08';
}
.icon-map3:before {
  content: '\eb09';
}
.icon-map22:before {
  content: '\eb0a';
}
.icon-history:before {
  content: '\eb0b';
}
.icon-clock4:before {
  content: '\eb0c';
}
.icon-clock22:before {
  content: '\eb0d';
}
.icon-alarm:before {
  content: '\eb0e';
}
.icon-bell3:before {
  content: '\eb0f';
}
.icon-stopwatch2:before {
  content: '\eb10';
}
.icon-calendar4:before {
  content: '\eb11';
}
.icon-printer3:before {
  content: '\eb12';
}
.icon-keyboard2:before {
  content: '\eb13';
}
.icon-display:before {
  content: '\eb14';
}
.icon-laptop3:before {
  content: '\eb15';
}
.icon-mobile3:before {
  content: '\eb16';
}
.icon-mobile22:before {
  content: '\eb17';
}
.icon-tablet3:before {
  content: '\eb18';
}
.icon-tv2:before {
  content: '\eb19';
}
.icon-drawer:before {
  content: '\eb1a';
}
.icon-drawer2:before {
  content: '\eb1b';
}
.icon-box-add:before {
  content: '\eb1c';
}
.icon-box-remove:before {
  content: '\eb1d';
}
.icon-download4:before {
  content: '\eb1e';
}
.icon-upload4:before {
  content: '\eb1f';
}
.icon-floppy-disk:before {
  content: '\eb20';
}
.icon-drive2:before {
  content: '\eb21';
}
.icon-database2:before {
  content: '\eb22';
}
.icon-undo:before {
  content: '\eb23';
}
.icon-redo:before {
  content: '\eb24';
}
.icon-undo2:before {
  content: '\eb25';
}
.icon-redo2:before {
  content: '\eb26';
}
.icon-forward3:before {
  content: '\eb27';
}
.icon-reply3:before {
  content: '\eb28';
}
.icon-bubble2:before {
  content: '\eb29';
}
.icon-bubbles2:before {
  content: '\eb2a';
}
.icon-bubbles22:before {
  content: '\eb2b';
}
.icon-bubble22:before {
  content: '\eb2c';
}
.icon-bubbles3:before {
  content: '\eb2d';
}
.icon-bubbles4:before {
  content: '\eb2e';
}
.icon-user3:before {
  content: '\eb2f';
}
.icon-users3:before {
  content: '\eb30';
}
.icon-user-plus:before {
  content: '\eb31';
}
.icon-user-minus:before {
  content: '\eb32';
}
.icon-user-check:before {
  content: '\eb33';
}
.icon-user-tie:before {
  content: '\eb34';
}
.icon-quotes-left:before {
  content: '\eb35';
}
.icon-quotes-right:before {
  content: '\eb36';
}
.icon-hour-glass2:before {
  content: '\eb37';
}
.icon-spinner:before {
  content: '\eb38';
}
.icon-spinner2:before {
  content: '\eb39';
}
.icon-spinner3:before {
  content: '\eb3a';
}
.icon-spinner4:before {
  content: '\eb3b';
}
.icon-spinner5:before {
  content: '\eb3c';
}
.icon-spinner6:before {
  content: '\eb3d';
}
.icon-spinner7:before {
  content: '\eb3e';
}
.icon-spinner8:before {
  content: '\eb3f';
}
.icon-spinner9:before {
  content: '\eb40';
}
.icon-spinner10:before {
  content: '\eb41';
}
.icon-spinner11:before {
  content: '\eb42';
}
.icon-binoculars:before {
  content: '\eb43';
}
.icon-search3:before {
  content: '\eb44';
}
.icon-zoom-in:before {
  content: '\eb45';
}
.icon-zoom-out:before {
  content: '\eb46';
}
.icon-enlarge:before {
  content: '\eb47';
}
.icon-shrink:before {
  content: '\eb48';
}
.icon-enlarge2:before {
  content: '\eb49';
}
.icon-shrink2:before {
  content: '\eb4a';
}
.icon-key3:before {
  content: '\eb4b';
}
.icon-key22:before {
  content: '\eb4c';
}
.icon-lock3:before {
  content: '\eb4d';
}
.icon-unlocked:before {
  content: '\eb4e';
}
.icon-wrench:before {
  content: '\eb4f';
}
.icon-equalizer:before {
  content: '\eb50';
}
.icon-equalizer2:before {
  content: '\eb51';
}
.icon-cog3:before {
  content: '\eb52';
}
.icon-cogs:before {
  content: '\eb53';
}
.icon-hammer:before {
  content: '\eb54';
}
.icon-magic-wand:before {
  content: '\eb55';
}
.icon-aid-kit:before {
  content: '\eb56';
}
.icon-bug:before {
  content: '\eb57';
}
.icon-pie-chart2:before {
  content: '\eb58';
}
.icon-stats-dots:before {
  content: '\eb59';
}
.icon-stats-bars:before {
  content: '\eb5a';
}
.icon-stats-bars2:before {
  content: '\eb5b';
}
.icon-trophy4:before {
  content: '\eb5c';
}
.icon-gift2:before {
  content: '\eb5d';
}
.icon-glass:before {
  content: '\eb5e';
}
.icon-glass2:before {
  content: '\eb5f';
}
.icon-mug:before {
  content: '\eb60';
}
.icon-spoon-knife:before {
  content: '\eb61';
}
.icon-leaf2:before {
  content: '\eb62';
}
.icon-rocket2:before {
  content: '\eb63';
}
.icon-meter:before {
  content: '\eb64';
}
.icon-meter2:before {
  content: '\eb65';
}
.icon-hammer2:before {
  content: '\eb66';
}
.icon-fire:before {
  content: '\eb67';
}
.icon-lab:before {
  content: '\eb68';
}
.icon-magnet2:before {
  content: '\eb69';
}
.icon-bin:before {
  content: '\eb6a';
}
.icon-bin2:before {
  content: '\eb6b';
}
.icon-briefcase3:before {
  content: '\eb6c';
}
.icon-airplane:before {
  content: '\eb6d';
}
.icon-truck:before {
  content: '\eb6e';
}
.icon-road:before {
  content: '\eb6f';
}
.icon-accessibility:before {
  content: '\eb70';
}
.icon-target3:before {
  content: '\eb71';
}
.icon-shield3:before {
  content: '\eb72';
}
.icon-power:before {
  content: '\eb73';
}
.icon-switch3:before {
  content: '\eb74';
}
.icon-power-cord:before {
  content: '\eb75';
}
.icon-clipboard3:before {
  content: '\eb76';
}
.icon-list-numbered:before {
  content: '\eb77';
}
.icon-list2:before {
  content: '\eb78';
}
.icon-list22:before {
  content: '\eb79';
}
.icon-tree2:before {
  content: '\eb7a';
}
.icon-menu2:before {
  content: '\eb7b';
}
.icon-menu22:before {
  content: '\eb7c';
}
.icon-menu3:before {
  content: '\eb7d';
}
.icon-menu4:before {
  content: '\eb7e';
}
.icon-cloud4:before {
  content: '\eb7f';
}
.icon-cloud-download:before {
  content: '\eb80';
}
.icon-cloud-upload:before {
  content: '\eb81';
}
.icon-cloud-check:before {
  content: '\eb82';
}
.icon-download22:before {
  content: '\eb83';
}
.icon-upload22:before {
  content: '\eb84';
}
.icon-download32:before {
  content: '\eb85';
}
.icon-upload32:before {
  content: '\eb86';
}
.icon-sphere:before {
  content: '\eb87';
}
.icon-earth:before {
  content: '\eb88';
}
.icon-link2:before {
  content: '\eb89';
}
.icon-flag4:before {
  content: '\eb8a';
}
.icon-attachment3:before {
  content: '\eb8b';
}
.icon-eye3:before {
  content: '\eb8c';
}
.icon-eye-plus:before {
  content: '\eb8d';
}
.icon-eye-minus:before {
  content: '\eb8e';
}
.icon-eye-blocked:before {
  content: '\eb8f';
}
.icon-bookmark3:before {
  content: '\eb90';
}
.icon-bookmarks2:before {
  content: '\eb91';
}
.icon-sun2:before {
  content: '\eb92';
}
.icon-contrast:before {
  content: '\eb93';
}
.icon-brightness-contrast:before {
  content: '\eb94';
}
.icon-star-empty:before {
  content: '\eb95';
}
.icon-star-half:before {
  content: '\eb96';
}
.icon-star-full:before {
  content: '\eb97';
}
.icon-heart4:before {
  content: '\eb98';
}
.icon-heart-broken:before {
  content: '\eb99';
}
.icon-man2:before {
  content: '\eb9a';
}
.icon-woman:before {
  content: '\eb9b';
}
.icon-man-woman:before {
  content: '\eb9c';
}
.icon-happy2:before {
  content: '\eb9d';
}
.icon-happy22:before {
  content: '\eb9e';
}
.icon-smile:before {
  content: '\eb9f';
}
.icon-smile2:before {
  content: '\eba0';
}
.icon-tongue:before {
  content: '\eba1';
}
.icon-tongue2:before {
  content: '\eba2';
}
.icon-sad2:before {
  content: '\eba3';
}
.icon-sad22:before {
  content: '\eba4';
}
.icon-wink:before {
  content: '\eba5';
}
.icon-wink2:before {
  content: '\eba6';
}
.icon-grin:before {
  content: '\eba7';
}
.icon-grin2:before {
  content: '\eba8';
}
.icon-cool:before {
  content: '\eba9';
}
.icon-cool2:before {
  content: '\ebaa';
}
.icon-angry:before {
  content: '\ebab';
}
.icon-angry2:before {
  content: '\ebac';
}
.icon-evil:before {
  content: '\ebad';
}
.icon-evil2:before {
  content: '\ebae';
}
.icon-shocked:before {
  content: '\ebaf';
}
.icon-shocked2:before {
  content: '\ebb0';
}
.icon-baffled:before {
  content: '\ebb1';
}
.icon-baffled2:before {
  content: '\ebb2';
}
.icon-confused:before {
  content: '\ebb3';
}
.icon-confused2:before {
  content: '\ebb4';
}
.icon-neutral:before {
  content: '\ebb5';
}
.icon-neutral2:before {
  content: '\ebb6';
}
.icon-hipster:before {
  content: '\ebb7';
}
.icon-hipster2:before {
  content: '\ebb8';
}
.icon-wondering:before {
  content: '\ebb9';
}
.icon-wondering2:before {
  content: '\ebba';
}
.icon-sleepy:before {
  content: '\ebbb';
}
.icon-sleepy2:before {
  content: '\ebbc';
}
.icon-frustrated:before {
  content: '\ebbd';
}
.icon-frustrated2:before {
  content: '\ebbe';
}
.icon-crying:before {
  content: '\ebbf';
}
.icon-crying2:before {
  content: '\ebc0';
}
.icon-point-up:before {
  content: '\ebc1';
}
.icon-point-right:before {
  content: '\ebc2';
}
.icon-point-down:before {
  content: '\ebc3';
}
.icon-point-left:before {
  content: '\ebc4';
}
.icon-warning2:before {
  content: '\ebc5';
}
.icon-notification2:before {
  content: '\ebc6';
}
.icon-question:before {
  content: '\ebc7';
}
.icon-plus3:before {
  content: '\ebc8';
}
.icon-minus2:before {
  content: '\ebc9';
}
.icon-info2:before {
  content: '\ebca';
}
.icon-cancel-circle:before {
  content: '\ebcb';
}
.icon-blocked:before {
  content: '\ebcc';
}
.icon-cross3:before {
  content: '\ebcd';
}
.icon-checkmark:before {
  content: '\ebce';
}
.icon-checkmark2:before {
  content: '\ebcf';
}
.icon-spell-check:before {
  content: '\ebd0';
}
.icon-enter:before {
  content: '\ebd1';
}
.icon-exit:before {
  content: '\ebd2';
}
.icon-play22:before {
  content: '\ebd3';
}
.icon-pause:before {
  content: '\ebd4';
}
.icon-stop:before {
  content: '\ebd5';
}
.icon-previous:before {
  content: '\ebd6';
}
.icon-next2:before {
  content: '\ebd7';
}
.icon-backward:before {
  content: '\ebd8';
}
.icon-forward22:before {
  content: '\ebd9';
}
.icon-play3:before {
  content: '\ebda';
}
.icon-pause2:before {
  content: '\ebdb';
}
.icon-stop2:before {
  content: '\ebdc';
}
.icon-backward2:before {
  content: '\ebdd';
}
.icon-forward32:before {
  content: '\ebde';
}
.icon-first:before {
  content: '\ebdf';
}
.icon-last:before {
  content: '\ebe0';
}
.icon-previous2:before {
  content: '\ebe1';
}
.icon-next22:before {
  content: '\ebe2';
}
.icon-eject:before {
  content: '\ebe3';
}
.icon-volume-high:before {
  content: '\ebe4';
}
.icon-volume-medium:before {
  content: '\ebe5';
}
.icon-volume-low:before {
  content: '\ebe6';
}
.icon-volume-mute:before {
  content: '\ebe7';
}
.icon-volume-mute2:before {
  content: '\ebe8';
}
.icon-volume-increase:before {
  content: '\ebe9';
}
.icon-volume-decrease:before {
  content: '\ebea';
}
.icon-loop2:before {
  content: '\ebeb';
}
.icon-loop22:before {
  content: '\ebec';
}
.icon-infinite:before {
  content: '\ebed';
}
.icon-shuffle2:before {
  content: '\ebee';
}
.icon-arrow-up-left:before {
  content: '\ebef';
}
.icon-arrow-up2:before {
  content: '\ebf0';
}
.icon-arrow-up-right:before {
  content: '\ebf1';
}
.icon-arrow-right2:before {
  content: '\ebf2';
}
.icon-arrow-down-right:before {
  content: '\ebf3';
}
.icon-arrow-down2:before {
  content: '\ebf4';
}
.icon-arrow-down-left:before {
  content: '\ebf5';
}
.icon-arrow-left2:before {
  content: '\ebf6';
}
.icon-arrow-up-left2:before {
  content: '\ebf7';
}
.icon-arrow-up22:before {
  content: '\ebf8';
}
.icon-arrow-up-right2:before {
  content: '\ebf9';
}
.icon-arrow-right22:before {
  content: '\ebfa';
}
.icon-arrow-down-right2:before {
  content: '\ebfb';
}
.icon-arrow-down22:before {
  content: '\ebfc';
}
.icon-arrow-down-left2:before {
  content: '\ebfd';
}
.icon-arrow-left22:before {
  content: '\ebfe';
}
.icon-circle-up:before {
  content: '\e900';
}
.icon-circle-right:before {
  content: '\ebff';
}
.icon-circle-down:before {
  content: '\ec00';
}
.icon-circle-left:before {
  content: '\ec01';
}
.icon-tab:before {
  content: '\ec02';
}
.icon-move-up:before {
  content: '\ec03';
}
.icon-move-down:before {
  content: '\ec04';
}
.icon-sort-alpha-asc:before {
  content: '\ec05';
}
.icon-sort-alpha-desc:before {
  content: '\ec06';
}
.icon-sort-numeric-asc:before {
  content: '\ec07';
}
.icon-sort-numberic-desc:before {
  content: '\ec08';
}
.icon-sort-amount-asc:before {
  content: '\ec09';
}
.icon-sort-amount-desc:before {
  content: '\ec0a';
}
.icon-command:before {
  content: '\ec0b';
}
.icon-shift:before {
  content: '\ec0c';
}
.icon-ctrl:before {
  content: '\ec0d';
}
.icon-opt:before {
  content: '\ec0e';
}
.icon-checkbox-checked:before {
  content: '\ec0f';
}
.icon-checkbox-unchecked:before {
  content: '\ec10';
}
.icon-radio-checked:before {
  content: '\ec11';
}
.icon-radio-checked2:before {
  content: '\ec12';
}
.icon-radio-unchecked:before {
  content: '\ec13';
}
.icon-crop:before {
  content: '\ec14';
}
.icon-make-group:before {
  content: '\ec15';
}
.icon-ungroup:before {
  content: '\ec16';
}
.icon-scissors3:before {
  content: '\ec17';
}
.icon-filter:before {
  content: '\ec18';
}
.icon-font:before {
  content: '\ec19';
}
.icon-ligature:before {
  content: '\ec1a';
}
.icon-ligature2:before {
  content: '\ec1b';
}
.icon-text-height:before {
  content: '\ec1c';
}
.icon-text-width:before {
  content: '\ec1d';
}
.icon-font-size:before {
  content: '\ec1e';
}
.icon-bold:before {
  content: '\ec1f';
}
.icon-underline:before {
  content: '\ec20';
}
.icon-italic:before {
  content: '\ec21';
}
.icon-strikethrough:before {
  content: '\ec22';
}
.icon-omega:before {
  content: '\ec23';
}
.icon-sigma:before {
  content: '\ec24';
}
.icon-page-break:before {
  content: '\ec25';
}
.icon-superscript:before {
  content: '\ec26';
}
.icon-subscript:before {
  content: '\ec27';
}
.icon-superscript2:before {
  content: '\ec28';
}
.icon-subscript2:before {
  content: '\ec29';
}
.icon-text-color:before {
  content: '\ec2a';
}
.icon-pagebreak:before {
  content: '\ec2b';
}
.icon-clear-formatting:before {
  content: '\ec2c';
}
.icon-table:before {
  content: '\ec2d';
}
.icon-table2:before {
  content: '\ec2e';
}
.icon-insert-template:before {
  content: '\ec2f';
}
.icon-pilcrow:before {
  content: '\ec30';
}
.icon-ltr:before {
  content: '\ec31';
}
.icon-rtl:before {
  content: '\ec32';
}
.icon-section:before {
  content: '\ec33';
}
.icon-paragraph-left:before {
  content: '\ec34';
}
.icon-paragraph-center:before {
  content: '\ec35';
}
.icon-paragraph-right:before {
  content: '\ec36';
}
.icon-paragraph-justify:before {
  content: '\ec37';
}
.icon-indent-increase:before {
  content: '\ec38';
}
.icon-indent-decrease:before {
  content: '\ec39';
}
.icon-share2:before {
  content: '\ec3a';
}
.icon-new-tab:before {
  content: '\ec3b';
}
.icon-embed:before {
  content: '\ec3c';
}
.icon-embed2:before {
  content: '\ec3d';
}
.icon-terminal:before {
  content: '\ec3e';
}
.icon-share22:before {
  content: '\ec3f';
}
.icon-mail2:before {
  content: '\ec40';
}
.icon-mail22:before {
  content: '\ec41';
}
.icon-mail3:before {
  content: '\ec42';
}
.icon-mail4:before {
  content: '\ec43';
}
.icon-amazon:before {
  content: '\ec44';
}
.icon-google2:before {
  content: '\ec45';
}
.icon-google22:before {
  content: '\ec46';
}
.icon-google3:before {
  content: '\ec47';
}
.icon-google-plus:before {
  content: '\ec48';
}
.icon-google-plus2:before {
  content: '\ec49';
}
.icon-google-plus3:before {
  content: '\ec4a';
}
.icon-hangouts:before {
  content: '\ec4b';
}
.icon-google-drive2:before {
  content: '\ec4c';
}
.icon-facebook3:before {
  content: '\ec4d';
}
.icon-facebook22:before {
  content: '\ec4e';
}
.icon-instagram2:before {
  content: '\ec4f';
}
.icon-whatsapp:before {
  content: '\ec50';
}
.icon-spotify2:before {
  content: '\ec51';
}
.icon-telegram:before {
  content: '\ec52';
}
.icon-twitter3:before {
  content: '\ec53';
}
.icon-vine2:before {
  content: '\ec54';
}
.icon-vk2:before {
  content: '\ec55';
}
.icon-renren2:before {
  content: '\ec56';
}
.icon-sina-weibo2:before {
  content: '\ec57';
}
.icon-rss3:before {
  content: '\ec58';
}
.icon-rss22:before {
  content: '\ec59';
}
.icon-youtube2:before {
  content: '\ec5a';
}
.icon-youtube22:before {
  content: '\ec5b';
}
.icon-twitch:before {
  content: '\ec5c';
}
.icon-vimeo2:before {
  content: '\ec5d';
}
.icon-vimeo22:before {
  content: '\ec5e';
}
.icon-lanyrd:before {
  content: '\ec5f';
}
.icon-flickr2:before {
  content: '\ec60';
}
.icon-flickr22:before {
  content: '\ec61';
}
.icon-flickr3:before {
  content: '\ec62';
}
.icon-flickr4:before {
  content: '\ec63';
}
.icon-dribbble3:before {
  content: '\ec64';
}
.icon-behance2:before {
  content: '\ec65';
}
.icon-behance22:before {
  content: '\ec66';
}
.icon-deviantart:before {
  content: '\ec67';
}
.icon-500px2:before {
  content: '\ec68';
}
.icon-steam:before {
  content: '\ec69';
}
.icon-steam2:before {
  content: '\ec6a';
}
.icon-dropbox2:before {
  content: '\ec6b';
}
.icon-onedrive2:before {
  content: '\ec6c';
}
.icon-github2:before {
  content: '\ec6d';
}
.icon-npm:before {
  content: '\ec6e';
}
.icon-basecamp2:before {
  content: '\ec6f';
}
.icon-trello:before {
  content: '\ec70';
}
.icon-wordpress:before {
  content: '\ec71';
}
.icon-joomla:before {
  content: '\ec72';
}
.icon-ello:before {
  content: '\ec73';
}
.icon-blogger:before {
  content: '\ec74';
}
.icon-blogger2:before {
  content: '\ec75';
}
.icon-tumblr3:before {
  content: '\ec76';
}
.icon-tumblr22:before {
  content: '\ec77';
}
.icon-yahoo:before {
  content: '\ec78';
}
.icon-yahoo2:before {
  content: '\ec79';
}
.icon-tux:before {
  content: '\ec7a';
}
.icon-appleinc:before {
  content: '\ec7b';
}
.icon-finder:before {
  content: '\ec7c';
}
.icon-android:before {
  content: '\ec7d';
}
.icon-windows2:before {
  content: '\ec7e';
}
.icon-windows8:before {
  content: '\ec7f';
}
.icon-soundcloud2:before {
  content: '\ec80';
}
.icon-soundcloud22:before {
  content: '\ec81';
}
.icon-skype2:before {
  content: '\ec82';
}
.icon-reddit:before {
  content: '\ec83';
}
.icon-hackernews:before {
  content: '\ec84';
}
.icon-wikipedia:before {
  content: '\ec85';
}
.icon-linkedin3:before {
  content: '\ec86';
}
.icon-linkedin22:before {
  content: '\ec87';
}
.icon-lastfm2:before {
  content: '\ec88';
}
.icon-lastfm22:before {
  content: '\ec89';
}
.icon-delicious:before {
  content: '\ec8a';
}
.icon-stumbleupon2:before {
  content: '\ec8b';
}
.icon-stumbleupon22:before {
  content: '\ec8c';
}
.icon-stackoverflow:before {
  content: '\ec8d';
}
.icon-pinterest2:before {
  content: '\ec8e';
}
.icon-pinterest22:before {
  content: '\ec8f';
}
.icon-xing2:before {
  content: '\ec90';
}
.icon-xing22:before {
  content: '\ec91';
}
.icon-flattr2:before {
  content: '\ec92';
}
.icon-foursquare2:before {
  content: '\ec93';
}
.icon-yelp2:before {
  content: '\ec94';
}
.icon-paypal2:before {
  content: '\ec95';
}
.icon-chrome:before {
  content: '\ec96';
}
.icon-firefox:before {
  content: '\ec97';
}
.icon-IE:before {
  content: '\ec98';
}
.icon-edge:before {
  content: '\ec99';
}
.icon-safari:before {
  content: '\ec9a';
}
.icon-opera:before {
  content: '\ec9b';
}
.icon-file-pdf:before {
  content: '\ec9c';
}
.icon-file-openoffice:before {
  content: '\ec9d';
}
.icon-file-word:before {
  content: '\ec9e';
}
.icon-file-excel:before {
  content: '\ec9f';
}
.icon-libreoffice:before {
  content: '\eca0';
}
.icon-html-five:before {
  content: '\eca1';
}
.icon-html-five2:before {
  content: '\eca2';
}
.icon-css3:before {
  content: '\eca3';
}
.icon-git:before {
  content: '\eca4';
}
.icon-codepen:before {
  content: '\eca5';
}
.icon-svg:before {
  content: '\eca6';
}
.icon-IcoMoon:before {
  content: '\eca7';
}
.icon-iiii:before {
  content: '\1F310';
}
