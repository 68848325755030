/* NEW  */

.about {
  $cubic: cubic-bezier(0.64, 0.01, 0.07, 1.65);
  $transition: 0.6s $cubic;
  $size: 40px;
  position: fixed;
  z-index: 10;
  bottom: 10px;
  right: 10px;
  width: $size;
  height: $size;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  transition: all 0.2s ease;

  .bg_links {
    width: $size;
    height: $size;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(#000000, 0.2);
    border-radius: 100%;
    backdrop-filter: blur(5px);
    position: absolute;
  }

  .logo {
    width: $size;
    height: $size;
    z-index: 9;
    background-image: url(https://rafaelavlucas.github.io/assets/codepen/logo_white.svg);
    background-size: 50%;
    background-repeat: no-repeat;
    background-position: 10px 7px;
    opacity: 0.9;
    transition: all 1s 0.2s ease;
    bottom: 0;
    right: 0;
  }

  .social {
    opacity: 0;
    right: 0;
    bottom: 0;

    .icon {
      width: 100%;
      height: 100%;
      background-size: 20px;
      background-repeat: no-repeat;
      background-position: center;
      background-color: transparent;
      display: flex;
      transition: all 0.2s ease, background-color 0.4s ease;
      opacity: 0;
      border-radius: 100%;
    }

    &.portfolio {
      transition: all 0.8s ease;

      .icon {
        background-image: url(https://rafaelavlucas.github.io/assets/codepen/link.svg);
      }
    }

    &.dribbble {
      transition: all 0.3s ease;
      .icon {
        background-image: url(https://rafaelavlucas.github.io/assets/codepen/dribbble.svg);
      }
    }

    &.linkedin {
      transition: all 0.8s ease;
      .icon {
        background-image: url(https://rafaelavlucas.github.io/assets/codepen/linkedin.svg);
      }
    }
  }

  &:hover {
    width: 105px;
    height: 105px;
    transition: all $transition;

    .logo {
      opacity: 1;
      transition: all 0.6s ease;
    }

    .social {
      opacity: 1;

      .icon {
        opacity: 0.9;
      }

      &:hover {
        background-size: 28px;
        .icon {
          background-size: 65%;
          opacity: 1;
        }
      }

      &.portfolio {
        right: 0;
        bottom: calc(100% - 40px);
        transition: all 0.3s 0s $cubic;
        .icon {
          &:hover {
            background-color: #698fb7;
          }
        }
      }

      &.dribbble {
        bottom: 45%;
        right: 45%;
        transition: all 0.3s 0.15s $cubic;
        .icon {
          &:hover {
            background-color: #ea4c89;
          }
        }
      }

      &.linkedin {
        bottom: 0;
        right: calc(100% - 40px);
        transition: all 0.3s 0.25s $cubic;
        .icon {
          &:hover {
            background-color: #0077b5;
          }
        }
      }
    }
  }
}

@import url('https://fonts.googleapis.com/css?family=Oxygen:700&display=swap');

body {
  margin: 0;
}

$bg: #171f30;
$c-01: #5389a6;
$c-02: #a6dcee;

$font-01: 'Oxygen', sans-serif;

.content {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 2;
  cursor: pointer;
  position: absolute;

  .loading {
    width: 80px;
    height: 50px;
    position: relative;

    p {
      top: 0;
      padding: 0;
      margin: 0;
      color: $c-01;
      font-family: $font-01;
      animation: text 3.5s ease both infinite;
      font-size: 12px;
      letter-spacing: 1px;

      @keyframes text {
        0% {
          letter-spacing: 1px;
          transform: translateX(0px);
        }

        40% {
          letter-spacing: 2px;
          transform: translateX(26px);
        }

        80% {
          letter-spacing: 1px;
          transform: translateX(32px);
        }

        90% {
          letter-spacing: 2px;
          transform: translateX(0px);
        }

        100% {
          letter-spacing: 1px;
          transform: translateX(0px);
        }
      }
    }
    span {
      background-color: $c-01;
      border-radius: 50px;
      display: block;
      height: 16px;
      width: 16px;
      bottom: 0;
      position: absolute;
      transform: translateX(64px);
      animation: loading 3.5s ease both infinite;

      &:before {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        background-color: $c-02;
        border-radius: inherit;
        animation: loading2 3.5s ease both infinite;
      }

      @keyframes loading {
        0% {
          width: 16px;
          transform: translateX(0px);
        }

        40% {
          width: 100%;
          transform: translateX(0px);
        }

        80% {
          width: 16px;
          transform: translateX(64px);
        }

        90% {
          width: 100%;
          transform: translateX(0px);
        }

        100% {
          width: 16px;
          transform: translateX(0px);
        }
      }
      @keyframes loading2 {
        0% {
          transform: translateX(0px);
          width: 16px;
        }

        40% {
          transform: translateX(0%);
          width: 80%;
        }

        80% {
          width: 100%;
          transform: translateX(0px);
        }

        90% {
          width: 80%;
          transform: translateX(15px);
        }
        100% {
          transform: translateX(0px);
          width: 16px;
        }
      }
    }
  }
}

/* OLD */
.loading-overlay {
}

.w-layout-grid {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  grid-row-gap: 16px;
  grid-column-gap: 16px;
}

.brix---btn-primary-full-width {
  width: 100%;
  max-width: 100%;
  padding: 26px 38px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 48px;
  background-color: #000;
  -webkit-transition: background-color 300ms ease, color 300ms ease,
    -webkit-transform 300ms ease;
  transition: background-color 300ms ease, color 300ms ease,
    -webkit-transform 300ms ease;
  transition: background-color 300ms ease, transform 300ms ease,
    color 300ms ease;
  transition: background-color 300ms ease, transform 300ms ease,
    color 300ms ease, -webkit-transform 300ms ease;
  color: #fff;
  font-size: 18px;
  line-height: 20px;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.brix---btn-primary-full-width:hover {
  background-color: #281ca5;
  -webkit-transform: translate3d(0px, -3px, 0.01px);
  transform: translate3d(0px, -3px, 0.01px);
  color: #fff;
}

.brix---text-200-list-bold {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  color: #000;
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
}

.brix---color-neutral-800 {
  color: #211f54;
}

.brix---icon-list {
  margin-right: 16px;
  background-color: transparent;
  color: transparent;
}

.brix---icon-list-item-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.brix---grid-1-column-gap-row-16px {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.brix---mg-bottom-40px {
  margin-bottom: 40px;
}

.brix---divider-40px {
  height: 1px;
  margin-top: 40px;
  margin-bottom: 40px;
  background-color: #dcddeb;
}

.brix---paragraph-default {
  margin-bottom: 0px;
  padding-left: 13px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  color: #000;
  font-size: 18px;
  line-height: 30px;
}

.brix---color-neutral-600 {
  color: #6e7191;
}

.brix---text-300-medium {
  color: #000;
  font-size: 20px;
  line-height: 22px;
  font-weight: 500;
}

.brix---heading-h1-size {
  margin-top: 0px;
  margin-bottom: 16px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  color: #000;
  font-size: 54px;
  line-height: 66px;
  font-weight: 700;
}

.brix---mg-right-8px {
  margin-right: 8px;
}

.brix---flex-horizontal-start {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.brix---heading-h3-size {
  margin-top: 0px;
  margin-bottom: 12px;
  padding-left: 13px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  color: #000;
  font-size: 24px;
  line-height: 34px;
  font-weight: 700;
}

.brix---mg-bottom-32px {
  margin-bottom: 32px;
}

.brix---pricing-content-v2 {
  padding: 40px 28px;
}

.brix---card {
  border-style: solid;
  border-width: 1px;
  border-color: #eff0f6;
  border-radius: 24px;
  background-color: #fff;
  box-shadow: 0 2px 7px 0 rgba(20, 20, 43, 0.06);
}

.brix---btn-primary-white-full-width {
  width: 100%;
  max-width: 100%;
  padding: 26px 38px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 48px;
  background-color: #fff;
  -webkit-transition: background-color 300ms ease, color 300ms ease,
    -webkit-transform 300ms ease;
  transition: background-color 300ms ease, color 300ms ease,
    -webkit-transform 300ms ease;
  transition: background-color 300ms ease, transform 300ms ease,
    color 300ms ease;
  transition: background-color 300ms ease, transform 300ms ease,
    color 300ms ease, -webkit-transform 300ms ease;
  color: #71a3d6;
  font-size: 18px;
  line-height: 20px;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.brix---btn-primary-white-full-width:hover {
  background-color: #f2f1ff;
  -webkit-transform: translate3d(0px, -3px, 0.01px);
  transform: translate3d(0px, -3px, 0.01px);
  color: #71a3d6;
}

.brix---color-neutral-100 {
  color: #fff;
}

.brix---badge-secondary-small-bold {
  display: inline-block;
  padding: 14px 22px;
  border-style: solid;
  border-width: 1px;
  border-color: #dcddeb;
  border-radius: 40px;
  background-color: #fff;
  box-shadow: 0 2px 12px 0 rgba(20, 20, 43, 0.06);
  color: #211f54;
  font-size: 16px;
  line-height: 18px;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
}

.brix---badge-wrapper-top-right {
  position: absolute;
  left: auto;
  top: 20px;
  right: 20px;
  bottom: auto;
}

.brix---card-pricing-popular {
  position: relative;
  padding-left: 19px;
  border-style: solid;
  border-width: 1px;
  border-color: #eff0f6;
  border-radius: 24px;
  background-color: #acacac;
  box-shadow: 0 2px 7px 0 rgba(20, 20, 43, 0.06);
}

.brix---3-columns-1-col-tablet {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 28px;
  grid-row-gap: 28px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.brix---tabs-content {
  overflow: visible;
}

.brix---badge-secondary---tabs {
  display: inline-block;
  margin-right: 12px;
  margin-left: 12px;
  padding: 18px 28px;
  border-style: solid;
  border-width: 1px;
  border-color: #dcddeb;
  border-radius: 40px;
  background-color: #fff;
  box-shadow: 0 2px 12px 0 rgba(20, 20, 43, 0.06);
  -webkit-transition: background-color 300ms ease, border-color 300ms ease,
    color 300ms ease;
  transition: background-color 300ms ease, border-color 300ms ease,
    color 300ms ease;
  color: #211f54;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  text-decoration: none;
}

.brix---badge-secondary---tabs:hover {
  border-color: #71a3d6;
  background-color: #71a3d6;
  color: #fff;
}

.brix---badge-secondary---tabs.w--current {
  border-color: #71a3d6;
  background-color: #71a3d6;
  color: #fff;
}

.brix---tabs-menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 40px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.brix---subtitle {
  margin-bottom: 8px;
  color: #71a3d6;
  font-size: 18px;
  line-height: 20px;
  font-weight: 700;
  letter-spacing: 0.15em;
  text-transform: uppercase;
}

.brix---text-center {
  text-align: center;
}

.brix---inner-container-700px-center {
  max-width: 700px;
  margin-right: auto;
  margin-left: auto;
}

.brix---mg-bottom-48px {
  margin-bottom: 48px;
}

.brix---container-default {
  max-width: 1218px;
  padding-right: 24px;
  padding-left: 24px;
}

.brix---section {
  padding-top: 176px;
  padding-bottom: 176px;
  color: #fff;
}

.body {
  font-family: 'Open Sans', sans-serif;
}

@media screen and (max-width: 991px) {
  .brix---btn-primary-full-width {
    padding-top: 24px;
    padding-bottom: 24px;
  }

  .brix---divider-40px {
    margin-top: 48px;
    margin-bottom: 48px;
  }

  .brix---heading-h1-size {
    font-size: 48px;
    line-height: 60px;
  }

  .brix---heading-h3-size {
    font-size: 22px;
    line-height: 32px;
  }

  .brix---mg-bottom-32px {
    margin-bottom: 24px;
  }

  .brix---btn-primary-white-full-width {
    padding-top: 24px;
    padding-bottom: 24px;
  }

  .brix---3-columns-1-col-tablet {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .brix---mg-bottom-48px {
    margin-bottom: 40px;
  }

  .brix---section {
    padding-top: 118px;
    padding-bottom: 118px;
  }
}

@media screen and (max-width: 767px) {
  .brix---btn-primary-full-width {
    padding: 20px 32px;
  }

  .brix---text-200-list-bold {
    font-size: 16px;
  }

  .brix---mg-bottom-40px {
    margin-bottom: 24px;
  }

  .brix---divider-40px {
    margin-top: 48px;
    margin-bottom: 48px;
  }

  .brix---text-300-medium {
    font-size: 18px;
    line-height: 20px;
  }

  .brix---heading-h1-size {
    margin-bottom: 12px;
    font-size: 38px;
    line-height: 46px;
  }

  .brix---pricing-content-v2 {
    padding-right: 24px;
    padding-bottom: 40px;
    padding-left: 24px;
  }

  .brix---btn-primary-white-full-width {
    padding: 20px 32px;
  }

  .brix---badge-secondary-small-bold {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .brix---3-columns-1-col-tablet {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .brix---badge-secondary---tabs {
    margin: 8px;
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .brix---tabs-menu {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .brix---subtitle {
    font-size: 17px;
    line-height: 19px;
  }

  .brix---mg-bottom-48px {
    margin-bottom: 32px;
  }

  .brix---section {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

@media screen and (max-width: 479px) {
  .brix---btn-primary-full-width {
    width: 100%;
    padding-top: 18px;
    padding-bottom: 18px;
    font-size: 16px;
    line-height: 18px;
  }

  .brix---icon-list {
    width: 24px;
    margin-right: 8px;
  }

  .brix---divider-40px {
    margin-top: 32px;
    margin-bottom: 32px;
  }

  .brix---paragraph-default {
    font-size: 17px;
    line-height: 28px;
  }

  .brix---heading-h1-size {
    margin-bottom: 10px;
    font-size: 30px;
    line-height: 37px;
  }

  .brix---heading-h3-size {
    margin-bottom: 10px;
    font-size: 20px;
    line-height: 30px;
  }

  .brix---btn-primary-white-full-width {
    width: 100%;
    padding-top: 18px;
    padding-bottom: 18px;
  }

  .brix---badge-secondary-small-bold {
    padding: 10px 16px;
    font-size: 14px;
    line-height: 16px;
  }

  .brix---badge-wrapper-top-right {
    top: 14px;
    right: 14px;
  }

  .brix---3-columns-1-col-tablet {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .brix---badge-secondary---tabs {
    margin-right: 0px;
    margin-left: 0px;
    padding: 14px 22px;
    font-size: 16px;
    line-height: 18px;
  }

  .brix---subtitle {
    font-size: 14px;
    line-height: 18px;
  }

  .brix---mg-bottom-48px {
    margin-bottom: 32px;
  }

  .brix---container-default {
    padding-right: 20px;
    padding-left: 20px;
  }
}
