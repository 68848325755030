.main__video-iframe {
  width: 60vw;
}

.main__container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}
.main__video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
