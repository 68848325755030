@font-face {
  font-family: 'icomoon';
  src: url('../../../public/fonts/icomoon/icomoon.eot?srf3rx');
  src: url('../../../public/fonts/icomoon/icomoon.eot?srf3rx#iefix')
      format('embedded-opentype'),
    url('../../../public/fonts/icomoon/icomoon.ttf?srf3rx') format('truetype'),
    url('../../../public/fonts/icomoon/icomoon.woff?srf3rx') format('woff'),
    url('../../../public/fonts/icomoon/icomoon.svg?srf3rx#icomoon')
      format('svg');
  font-weight: normal;
  font-style: normal;
}
/* =======================================================
*
* 	Template Style 
*	Edit this section
*
* ======================================================= */
body {
  font-family: 'Roboto', Arial, sans-serif;
  line-height: 1.8;
  font-size: 16px;
  background: #fff;
  color: #848484;
  font-weight: 300;
  overflow-x: hidden;
}
body.fh5co-offcanvas {
  overflow: hidden;
}

a {
  color: #71a3d6;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}
a:hover {
  text-decoration: none !important;
  color: #71a3d6 !important;
}

p,
ul,
ol {
  margin-bottom: 1.5em;
  font-size: 20px;
  color: #848484;
  font-family: 'Roboto', Arial, sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #212121;
  font-family: 'Roboto', Arial, sans-serif;
  font-weight: 400;
  margin: 0 0 30px 0;
}

figure {
  margin-bottom: 2em;
}

::-webkit-selection {
  color: #fcfcfc;
  background: #71a3d6;
}

::-moz-selection {
  color: #fcfcfc;
  background: #71a3d6;
}

::selection {
  color: #fcfcfc;
  background: #71a3d6;
}

#fh5co-header {
  position: relative;
  margin-bottom: 0;
  z-index: 9999999;
}

.fh5co-cover,
.fh5co-hero {
  position: relative;
  height: 800px;
}
@media screen and (max-width: 768px) {
  .fh5co-cover,
  .fh5co-hero {
    height: 700px;
  }
}
.fh5co-cover.fh5co-hero-2,
.fh5co-hero.fh5co-hero-2 {
  height: 600px;
}
.fh5co-cover.fh5co-hero-2 .fh5co-overlay,
.fh5co-hero.fh5co-hero-2 .fh5co-overlay {
  position: absolute !important;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  background: rgba(0, 0, 0, 0.7);
}

.fh5co-overlay {
  position: absolute !important;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  background: rgba(0, 0, 0, 0.3);
}

.fh5co-cover {
  background-size: cover;
  position: relative;
}

.aaaaa {
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 28px;
  grid-row-gap: 28px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
}
@media screen and (max-width: 768px) {
  .fh5co-cover {
    heifght: inherit;
    padding: 3em 0;
  }
}
.fh5co-cover .btn {
  padding: 1em 2em;
  opacity: 0.9;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  border-radius: 30px;
}
.fh5co-cover.fh5co-cover_2 {
  height: 600px;
}
@media screen and (max-width: 768px) {
  .fh5co-cover.fh5co-cover_2 {
    heifght: inherit;
    padding: 3em 0;
  }
}
.fh5co-cover .desc {
  top: 50%;
  position: absolute;
  width: 100%;
  margin-top: -100px;
  z-index: 2;
  color: #fff;
}
@media screen and (max-width: 768px) {
  .fh5co-cover .desc {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.fh5co-cover .desc h2 {
  color: #fff;
  font-size: 55px;
  font-weight: 300;
  margin-bottom: 10px;
  line-height: 60px;
  letter-spacing: 1px;
}
@media screen and (max-width: 768px) {
  .fh5co-cover .desc h2 {
    font-size: 40px;
    line-height: 50px;
  }
}
.fh5co-cover .desc span {
  display: block;
  margin-bottom: 25px;
  font-size: 24px;
  letter-spacing: 1px;
  color: rgba(255, 255, 255, 0.8);
}
.fh5co-cover .desc span i {
  color: #da1212;
}
.fh5co-cover .desc span a {
  color: rgba(255, 255, 255, 0.8);
}
.fh5co-cover .desc span a:hover,
.fh5co-cover .desc span a:focus {
  color: #fff !important;
}
.fh5co-cover .desc span a.fh5co-site-name {
  padding-bottom: 2px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.7);
}

/* Superfish Override Menu */
.sf-menu {
  margin: 0 !important;
}

.sf-menu {
  float: right;
}

.sf-menu ul {
  box-shadow: none;
  border: transparent;
  min-width: 12em;
  *width: 12em;
}

.new-dev {
  margin-top: 5vw;
}

.sf-menu a {
  color: rgba(255, 255, 255, 0.5);
  padding: 0.75em 1em !important;
  font-weight: normal;
  border-left: none;
  border-top: none;
  border-top: none;
  text-decoration: none;
  zoom: 1;
  font-size: 16px;
  border-bottom: none !important;
  font-weight: 300;
}
.sf-menu a:hover,
.sf-menu a:focus {
  color: #fff !important;
}

.sf-menu li.active a:hover,
.sf-menu li.active a:focus {
  color: #fff;
}

.sf-menu li,
.sf-menu ul li,
.sf-menu ul ul li,
.sf-menu li:hover,
.sf-menu li.sfHover {
  background: transparent;
}

.sf-menu ul li a,
.sf-menu ul ul li a {
  text-transform: none;
  padding: 0.25em 1em !important;
  letter-spacing: 1px;
}

.sf-menu li:hover a,
.sf-menu li.sfHover a,
.sf-menu ul li:hover a,
.sf-menu ul li.sfHover a,
.sf-menu li.active a {
  color: #fff;
}

.sf-menu ul li:hover,
.sf-menu ul li.sfHover {
  background: transparent;
}

.sf-menu ul li {
  background: transparent;
}

.sf-arrows .sf-with-ul {
  padding-right: 2.5em;
  *padding-right: 1em;
}

.sf-arrows .sf-with-ul:after {
  content: '';
  position: absolute;
  top: 50%;
  right: 1em;
  margin-top: -3px;
  height: 0;
  width: 0;
  border: 5px solid transparent;
  border-top-color: #ccc;
}

.sf-arrows > li > .sf-with-ul:focus:after,
.sf-arrows > li:hover > .sf-with-ul:after,
.sf-arrows > .sfHover > .sf-with-ul:after {
  border-top-color: #ccc;
}

.sf-arrows ul .sf-with-ul:after {
  margin-top: -5px;
  margin-right: -3px;
  border-color: transparent;
  border-left-color: #ccc;
}

.sf-arrows ul li > .sf-with-ul:focus:after,
.sf-arrows ul li:hover > .sf-with-ul:after,
.sf-arrows ul .sfHover > .sf-with-ul:after {
  border-left-color: #ccc;
}

#fh5co-header-section {
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
}

.nav-header {
  margin-top: 40px;
  position: relative;
  float: left;
  width: 100%;
}

#fh5co-logo {
  z-index: 2;
  font-size: 38px;
  text-transform: uppercase;
  font-weight: 900;
  float: left;
  margin-bottom: 0;
  margin-top: 0;
}
#fh5co-logo a {
  position: relative;
}
#fh5co-logo a:after {
  position: absolute;
  content: '';
  top: -5px;
  left: -9px;
  background: #da1212;
  width: 10px;
  height: 10px;
}
#fh5co-logo span {
  padding: 0;
  margin: 0;
  font-weight: 100;
}
@media screen and (max-width: 768px) {
  #fh5co-logo {
    width: 100%;
    float: none;
    text-align: center;
  }
}
#fh5co-logo a {
  color: #fff;
}
#fh5co-logo a:hover,
#fh5co-logo a:focus {
  color: #fff !important;
}

#fh5co-menu-wrap {
  position: relative;
  z-index: 2;
  display: block;
  float: right;
}

#fh5co-primary-menu > li > ul li.active > a {
  color: #71a3d6 !important;
}
#fh5co-primary-menu > li > .sf-with-ul:after {
  border: none !important;
  font-family: 'icomoon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  float: right;
  margin-right: -5px;
  top: 50%;
  margin-top: -7px;
  content: '\e922';
  color: rgba(255, 255, 255, 0.5);
}
#fh5co-primary-menu > li > ul li {
  position: relative;
}
#fh5co-primary-menu > li > ul li > .sf-with-ul:after {
  border: none !important;
  font-family: 'icomoon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  float: right;
  margin-right: 10px;
  top: 20px;
  font-size: 16px;
  content: '\e924';
  color: rgba(0, 0, 0, 0.5);
}

#fh5co-primary-menu .fh5co-sub-menu {
  padding: 10px 7px 10px;
  background: #fff;
  left: 0;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
  -ms-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
  -o-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
}
#fh5co-primary-menu .fh5co-sub-menu > li > .fh5co-sub-menu {
  left: 100%;
}

#fh5co-primary-menu .fh5co-sub-menu:before {
  position: absolute;
  top: -9px;
  left: 20px;
  width: 0;
  height: 0;
  content: '';
}

#fh5co-primary-menu .fh5co-sub-menu:after {
  position: absolute;
  top: -8px;
  left: 30px;
  width: 0;
  height: 0;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #fff;
  border-left: 8px solid transparent;
  content: '';
}

#fh5co-primary-menu .fh5co-sub-menu .fh5co-sub-menu:before {
  top: 6px;
  left: 100%;
}

#fh5co-primary-menu .fh5co-sub-menu .fh5co-sub-menu:after {
  top: 7px;
  right: 100%;
  border: none !important;
}

.site-header.has-image #primary-menu .sub-menu {
  border-color: #ebebeb;
  -webkit-box-shadow: 0 5px 15px 4px rgba(0, 0, 0, 0.09);
  -moz-box-shadow: 0 5px 15px 4px rgba(0, 0, 0, 0.09);
  -ms-box-shadow: 0 5px 15px 4px rgba(0, 0, 0, 0.09);
  -o-box-shadow: 0 5px 15px 4px rgba(0, 0, 0, 0.09);
  box-shadow: 0 5px 15px 4px rgba(0, 0, 0, 0.09);
}
.site-header.has-image #primary-menu .sub-menu:before {
  display: none;
}

#fh5co-primary-menu .fh5co-sub-menu a {
  letter-spacing: 0;
  padding: 0 15px;
  font-size: 18px;
  line-height: 34px;
  color: #575757 !important;
  text-transform: none;
  background: none;
}
#fh5co-primary-menu .fh5co-sub-menu a:hover {
  color: #71a3d6 !important;
}

#fh5co-programs-section,
#fh5co-schedule-section,
#fh5co-team-section,
#fh5co-blog-section,
#fh5co-contact {
  padding: 7em 0;
}
@media screen and (max-width: 768px) {
  #fh5co-programs-section,
  #fh5co-schedule-section,
  #fh5co-team-section,
  #fh5co-blog-section,
  #fh5co-contact {
    padding: 4em 0;
  }
}

.fh5co-lightgray-section {
  background: rgba(0, 0, 0, 0.04);
}

.heading-section {
  margin-bottom: 4em;
}
.heading-section h2 {
  font-size: 24px;
  letter-spacing: 1px;
  text-transform: uppercase;
  position: relative;
  font-weight: 900;
}

.program {
  margin-bottom: 4em;
}
.program img {
  color: red;
  width: 70px;
  margin-bottom: 30px;
}
.program h3 {
  font-size: 20px;
}
.program.program-schedule {
  margin-bottom: 2em;
  padding: 40px;
  background: #fff;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.program.program-schedule img {
  width: 40px !important;
}
.program.program-schedule h3 {
  margin-bottom: 0;
}
.program.program-schedule small {
  display: block;
  font-size: 12px;
}
.program.program-schedule:hover,
.program.program-schedule:focus {
  -webkit-box-shadow: 0px 6px 55px -23px rgba(0, 0, 0, 0.72);
  -moz-box-shadow: 0px 6px 55px -23px rgba(0, 0, 0, 0.72);
  box-shadow: 0px 6px 55px -23px rgba(0, 0, 0, 0.72);
}

.schedule {
  padding: 0;
  margin: 0 0 40px 0;
}
.schedule li {
  display: -moz-inline-stack;
  display: inline-block;
  zoom: 1;
  *display: inline;
  font-size: 16px;
}
.schedule li a {
  color: rgba(0, 0, 0, 0.5);
  padding: 5px 20px;
  cursor: pointer;
}
.schedule li a:active,
.schedule li a:focus {
  text-decoration: none;
}
.schedule li a.active {
  background: #71a3d6;
  color: #fff !important;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  border-radius: 30px;
}
@media screen and (max-width: 768px) {
  .schedule li {
    display: -moz-inline-stack;
    display: inline-block;
    zoom: 1;
    *display: inline;
    margin-bottom: 10px;
    font-size: 18px;
  }
}

.schedule-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.schedule-content.active {
  opacity: 1;
  visibility: visible;
}

.fh5co-table,
.fh5co-table-cell,
.fh5co-parallax {
  height: 500px;
}

.fh5co-parallax {
  background-color: #555;
  background-attachment: fixed;
  background-size: cover;
  position: relative;
}
.fh5co-parallax .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: black;
  opacity: 0.2;
}

@media screen and (max-width: 768px) {
  .fh5co-parallax {
    height: inherit;
    padding: 4em 0;
  }
}
.fh5co-parallax .fh5co-intro h1 {
  color: #fff;
  font-size: 70px;
  font-weight: 300;
  margin-bottom: 10px;
}
@media screen and (max-width: 768px) {
  .fh5co-parallax .fh5co-intro h1 {
    font-size: 30px;
  }
}
.fh5co-parallax .fh5co-intro p {
  color: #fff;
}
.fh5co-parallax .fh5co-intro p a {
  color: #fff;
  border-bottom: 2px solid rgba(255, 255, 255, 0.2);
}
.fh5co-parallax .fh5co-intro p a:hover {
  color: #fff;
}
.fh5co-parallax .fh5co-intro.box-area {
  padding: 40px;
  background: #222831;
}

.fh5co-table {
  display: table;
}

.fh5co-table-cell {
  display: table-cell;
  vertical-align: middle;
}

.fh5co-pricing {
  padding: 7em 0;
}
@media screen and (max-width: 768px) {
  .fh5co-pricing {
    padding: 3em 0;
  }
}
.fh5co-pricing .pricing {
  display: block;
  float: left;
  margin-bottom: 30px;
}
.fh5co-pricing .price-box {
  text-align: center;
  padding: 30px;
  background: #fff;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  margin-bottom: 40px;
  position: relative;
}
.fh5co-pricing .price-box.popular .btn-select-plan {
  background: #71a3d6;
}
.fh5co-pricing .price-box.popular .price {
  color: #71a3d6;
}
.fh5co-pricing .price-box .btn-select-plan {
  padding: 10px 20px;
  background: #5bc0de;
  color: #fff;
}
.fh5co-pricing .price-box .classes {
  padding: 0;
}
.fh5co-pricing .price-box .classes li {
  display: block;
  list-style: none;
  margin: 0;
  font-size: 16px;
  padding: 8px 10px;
}
.fh5co-pricing .price-box .classes li.color {
  background: rgba(0, 0, 0, 0.04);
}
.fh5co-pricing .pricing-plan {
  margin: 0 0 50px 0;
  padding: 0;
  font-size: 13px;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: 700;
  color: #888f94;
}
.fh5co-pricing .pricing-plan.pricing-plan-offer {
  margin-bottom: 24px;
}
.fh5co-pricing .pricing-plan span {
  display: block;
  margin-top: 10px;
  margin-bottom: 0;
  color: #d5d8db;
}
.fh5co-pricing .price {
  font-size: 72px;
  color: #6d6d6d;
  line-height: 50px;
}
.fh5co-pricing .price .currency {
  font-size: 30px;
  top: -0.9em;
  padding-right: 10px;
}
.fh5co-pricing .price small {
  font-size: 13px;
  display: block;
  text-transform: uppercase;
  color: #888f94;
}

.team-section-grid {
  position: relative;
  background-size: cover;
  height: 60vh;
  margin-bottom: 30px;
  overflow: hidden;
}
.team-section-grid .overlay-section {
  position: absolute;
  top: 0;
  bottom: 0px;
  /* bottom: -450px; rfq */
  left: 0;
  right: 0;
  opacity: 1;
  /* opacity: 0; rfq */
  background: rgba(0, 0, 0, 0.5);
  -webkit-transition: 0.6s;
  -o-transition: 0.6s;
  transition: 0.6s;
}
.team-section-grid .overlay-section h3 {
  color: #fff;
  margin-bottom: 0px;
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 3px;
}
.team-section-grid .overlay-section span {
  display: block;
  margin-bottom: 0px;
}

.team-section-grid .overlay-section p {
  color: rgba(255, 255, 255, 0.7);
  line-height: 1.5;
  background: #71a3d6;
  text-align: initial;
  padding: 5px;
  border-radius: 16px;
  border: 1px;
  font-size: 1.2vw;
}

.team-section-grid .overlay-section p.fh5co-social-icons a:hover,
.team-section-grid .overlay-section p.fh5co-social-icons a:focus {
  text-decoration: none !important;
}
.team-section-grid .overlay-section p.fh5co-social-icons i {
  font-size: 40px;
  color: #fff;
}
.team-section-grid .overlay-section span {
  color: #fff;
  display: block;
}
.team-section-grid .overlay-section .desc {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 30px;
}
.team-section-grid:hover .overlay-section {
  bottom: 0;
  opacity: 1;
}

.about {
  padding-bottom: 5em;
}
.about img {
  margin-bottom: 50px;
}

#fh5co-contact .contact-info {
  margin: 0;
  padding: 0;
}
#fh5co-contact .contact-info li {
  list-style: none;
  margin: 0 0 20px 0;
  position: relative;
  padding-left: 40px;
}
#fh5co-contact .contact-info li i {
  position: absolute;
  top: 0.3em;
  left: 0;
  font-size: 22px;
  color: rgba(0, 0, 0, 0.2);
}
#fh5co-contact .contact-info li a {
  font-weight: 400 !important;
  color: rgba(0, 0, 0, 0.3);
}
#fh5co-contact .form-control {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
  border: none;
  border: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 18px;
  font-weight: 400;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  border-radius: 0px;
}
#fh5co-contact .form-control:focus,
#fh5co-contact .form-control:active {
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
}
#fh5co-contact input[type='text'] {
  height: 50px;
}
#fh5co-contact .form-group {
  margin-bottom: 30px;
}

.section-title {
  font-weight: 900;
  text-transform: uppercase;
}

.fh5co-blog {
  margin-bottom: 50px;
  position: relative;
}
.fh5co-blog .inner-post {
  position: absolute;
  top: 0;
  left: 0;
  width: 200px;
  height: 150px;
}
.fh5co-blog .inner-post img {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
}
@media screen and (max-width: 768px) {
  .fh5co-blog .inner-post {
    width: 100%;
    position: relative !important;
    height: inherit !important;
    margin-bottom: 30px;
  }
}
.fh5co-blog .desc {
  padding-left: 230px;
}
@media screen and (max-width: 768px) {
  .fh5co-blog .desc {
    padding-left: 0;
  }
}
.fh5co-blog .desc h3 {
  font-size: 22px;
  margin-bottom: 10px;
  line-height: 32px;
}
.fh5co-blog .desc h3 a {
  color: rgba(0, 0, 0, 0.7);
}
.fh5co-blog .desc span {
  display: inline-block;
  margin-bottom: 15px;
  margin-right: 20px;
}
.fh5co-blog .desc.desc2 {
  padding-left: 100px;
}
@media screen and (max-width: 768px) {
  .fh5co-blog .desc.desc2 {
    padding-left: 0;
  }
}
.fh5co-blog .meta-date {
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px 20px;
  background: #71a3d6;
}
@media screen and (max-width: 768px) {
  .fh5co-blog .meta-date {
    position: relative;
    float: left;
    width: 100%;
    margin-bottom: 20px;
  }
}
.fh5co-blog .meta-date p {
  margin: 0;
  display: block;
  color: #fff;
}
.fh5co-blog .meta-date p span {
  display: block;
  font-size: 14px;
}
@media screen and (max-width: 768px) {
  .fh5co-blog .meta-date p span {
    float: left;
    margin-right: 10px;
    margin-top: 8px;
  }
}
.fh5co-blog .meta-date p span.date {
  font-size: 30px;
  font-weight: 900;
  line-height: 28px;
}
@media screen and (max-width: 768px) {
  .fh5co-blog .meta-date p span.date {
    display: -moz-inline-stack;
    display: inline-block;
    zoom: 1;
    *display: inline;
    float: left;
    margin-right: 10px;
    margin-top: 8px;
  }
}

#map {
  width: 100%;
  height: 500px;
  position: relative;
}
@media screen and (max-width: 768px) {
  #map {
    height: 200px;
  }
}

.fh5co-social-icons > a {
  padding: 4px 10px;
  color: #71a3d6;
}
.fh5co-social-icons > a i {
  font-size: 26px;
}
.fh5co-social-icons > a:hover,
.fh5co-social-icons > a:focus,
.fh5co-social-icons > a:active {
  text-decoration: none;
  color: #212121;
}

#footer {
  background: #222831;
  padding: 60px 0 40px;
}
#footer .section-title {
  color: rgba(255, 255, 255, 0.8);
}
#footer .contact-info {
  margin: 0 0 60px 0;
  padding: 0;
}
#footer .contact-info li {
  list-style: none;
  margin: 0 0 20px 0;
  position: relative;
  padding-left: 40px;
}
#footer .contact-info li i {
  position: absolute;
  top: 5px;
  left: 0;
  font-size: 22px;
  color: rgba(255, 255, 255, 0.7);
}
#footer .contact-info li a {
  color: rgba(255, 255, 255, 0.6);
}
#footer .contact-form .form-group input[type='name'],
#footer .contact-form .form-group input[type='text'],
#footer .contact-form .form-group input[type='email'],
#footer .contact-form .form-group textarea {
  font-size: 16px;
}
#footer .contact-form .form-group input[type='name']::-webkit-input-placeholder,
#footer .contact-form .form-group input[type='text']::-webkit-input-placeholder,
#footer
  .contact-form
  .form-group
  input[type='email']::-webkit-input-placeholder,
#footer .contact-form .form-group textarea::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
#footer .contact-form .form-group input[type='name']:-moz-placeholder,
#footer .contact-form .form-group input[type='text']:-moz-placeholder,
#footer .contact-form .form-group input[type='email']:-moz-placeholder,
#footer .contact-form .form-group textarea:-moz-placeholder {
  /* Firefox 18- */
  color: rgba(255, 255, 255, 0.5);
}
#footer .contact-form .form-group input[type='name']::-moz-placeholder,
#footer .contact-form .form-group input[type='text']::-moz-placeholder,
#footer .contact-form .form-group input[type='email']::-moz-placeholder,
#footer .contact-form .form-group textarea::-moz-placeholder {
  /* Firefox 19+ */
  color: rgba(255, 255, 255, 0.5);
}
#footer .contact-form .form-group input[type='name']:-ms-input-placeholder,
#footer .contact-form .form-group input[type='text']:-ms-input-placeholder,
#footer .contact-form .form-group input[type='email']:-ms-input-placeholder,
#footer .contact-form .form-group textarea:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
#footer .contact-form .form-group input {
  color: rgba(255, 255, 255, 0.5);
  background: transparent;
  border: none;
  background: rgba(0, 0, 0, 0.2);
  box-shadow: none;
}
#footer .contact-form .form-group textarea {
  color: rgba(255, 255, 255, 0.5);
  background: transparent;
  border: none;
  background: rgba(0, 0, 0, 0.2);
  box-shadow: none;
}
#footer #btn-submit {
  color: rgba(255, 255, 255, 0.9) !important;
  background: #71a3d6 !important;
}
#footer .copy-right {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding-top: 50px;
  margin-top: 50px;
}
#footer .copy-right p {
  color: rgba(255, 255, 255, 0.3);
  letter-spacing: 1px;
  font-size: 14px;
  text-transform: uppercase;
}
#footer .copy-right p a {
  color: rgba(255, 255, 255, 0.5);
}

.btn {
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.btn {
  text-transform: uppercase;
  letter-spacing: 2px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.btn.btn-primary {
  background: #71a3d6;
  color: #fff;
  border: none !important;
  border: 2px solid transparent !important;
}
.btn.btn-primary:hover,
.btn.btn-primary:active,
.btn.btn-primary:focus {
  box-shadow: none;
  background: #71a3d6;
  color: #fff !important;
}
.btn:hover,
.btn:active,
.btn:focus {
  background: #393e46 !important;
  color: #fff !important;
  outline: none !important;
}
.btn.btn-default:hover,
.btn.btn-default:focus,
.btn.btn-default:active {
  border-color: transparent;
}

.js .animate-box {
  opacity: 0;
}

#fh5co-wrapper {
  overflow-x: hidden;
  position: relative;
}

.fh5co-offcanvas {
  overflow: hidden;
}
.fh5co-offcanvas #fh5co-wrapper {
  overflow: hidden !important;
}

#fh5co-page {
  position: relative;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}
.fh5co-offcanvas #fh5co-page {
  -moz-transform: translateX(-240px);
  -webkit-transform: translateX(-240px);
  -ms-transform: translateX(-240px);
  -o-transform: translateX(-240px);
  transform: translateX(-240px);
}

@media screen and (max-width: 768px) {
  #fh5co-menu-wrap {
    display: none;
  }
}

#offcanvas-menu {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 99;
  background: #222;
  right: -240px;
  width: 240px;
  padding: 20px;
  overflow-y: scroll !important;
}
#offcanvas-menu ul {
  padding: 0;
  margin: 0;
}
#offcanvas-menu ul li {
  padding: 0;
  margin: 0 0 10px 0;
  list-style: none;
}
#offcanvas-menu ul li a {
  font-size: 18px;
  color: rgba(255, 255, 255, 0.4);
}
#offcanvas-menu ul li a:hover {
  color: #71a3d6;
}
#offcanvas-menu ul li > .fh5co-sub-menu {
  margin-top: 9px;
  padding-left: 20px;
}
#offcanvas-menu ul li.active > a {
  color: #71a3d6;
}
#offcanvas-menu ul .fh5co-sub-menu {
  display: block !important;
}

.fh5co-nav-toggle {
  cursor: pointer;
  text-decoration: none;
}
.fh5co-nav-toggle.active i::before,
.fh5co-nav-toggle.active i::after {
  background: #fff;
}
.fh5co-nav-toggle.dark.active i::before,
.fh5co-nav-toggle.dark.active i::after {
  background: #fff;
}
.fh5co-nav-toggle:hover,
.fh5co-nav-toggle:focus,
.fh5co-nav-toggle:active {
  outline: none;
  border-bottom: none !important;
}
.fh5co-nav-toggle i {
  position: relative;
  display: -moz-inline-stack;
  display: inline-block;
  zoom: 1;
  *display: inline;
  width: 30px;
  height: 2px;
  color: #fff;
  font: bold 14px/0.4 Helvetica;
  text-transform: uppercase;
  text-indent: -55px;
  background: #fff;
  transition: all 0.2s ease-out;
}
.fh5co-nav-toggle i::before,
.fh5co-nav-toggle i::after {
  content: '';
  width: 30px;
  height: 2px;
  background: #fff;
  position: absolute;
  left: 0;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
}
.fh5co-nav-toggle.dark i {
  position: relative;
  color: #000;
  background: #000;
  transition: all 0.2s ease-out;
}
.fh5co-nav-toggle.dark i::before,
.fh5co-nav-toggle.dark i::after {
  background: #000;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
}

.fh5co-nav-toggle i::before {
  top: -7px;
}

.fh5co-nav-toggle i::after {
  bottom: -7px;
}

.fh5co-nav-toggle:hover i::before {
  top: -10px;
}

.fh5co-nav-toggle:hover i::after {
  bottom: -10px;
}

.fh5co-nav-toggle.active i {
  background: transparent;
}

.fh5co-nav-toggle.active i::before {
  top: 0;
  -webkit-transform: rotateZ(45deg);
  -moz-transform: rotateZ(45deg);
  -ms-transform: rotateZ(45deg);
  -o-transform: rotateZ(45deg);
  transform: rotateZ(45deg);
}

.fh5co-nav-toggle.active i::after {
  bottom: 0;
  -webkit-transform: rotateZ(-45deg);
  -moz-transform: rotateZ(-45deg);
  -ms-transform: rotateZ(-45deg);
  -o-transform: rotateZ(-45deg);
  transform: rotateZ(-45deg);
}

.fh5co-nav-toggle {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 9999;
  display: block;
  margin: 0 auto;
  display: none;
  cursor: pointer;
}
@media screen and (max-width: 768px) {
  .fh5co-nav-toggle {
    display: block;
    top: 0px;
  }
}

@media screen and (max-width: 480px) {
  .col-xxs-12 {
    float: none;
    width: 100%;
  }
  .team-section-grid .overlay-section p {
    font-size: 14px;
    line-height: 1.5;
    background: #71a3d6;
    text-align: initial;
    padding: 5px;
    border-radius: 16px;
    border: 1px;
  }

  .team-section-grid {
    height: 75vh;
  }
}

.row-bottom-padded-lg {
  padding-bottom: 7em;
}
@media screen and (max-width: 768px) {
  .row-bottom-padded-lg {
    padding-bottom: 2em;
  }
}

.row-top-padded-lg {
  padding-top: 7em;
}
@media screen and (max-width: 768px) {
  .row-top-padded-lg {
    padding-top: 2em;
  }
}

.row-bottom-padded-md {
  padding-bottom: 4em;
}
@media screen and (max-width: 768px) {
  .row-bottom-padded-md {
    padding-bottom: 2em;
  }
}

.row-top-padded-md {
  padding-top: 4em;
}
@media screen and (max-width: 768px) {
  .row-top-padded-md {
    padding-top: 2em;
  }
}

.row-bottom-padded-sm {
  padding-bottom: 2em;
}
@media screen and (max-width: 768px) {
  .row-bottom-padded-sm {
    padding-bottom: 2em;
  }
}

.row-top-padded-sm {
  padding-top: 2em;
}
@media screen and (max-width: 768px) {
  .row-top-padded-sm {
    padding-top: 2em;
  }
  .aaaaa {
    display: grid;
    grid-auto-columns: 1fr;
    grid-column-gap: 28px;
    grid-row-gap: 28px;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  }
}

.border-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.lead {
  line-height: 1.8;
}

.no-js #loader {
  display: none;
}

.js #loader {
  display: block;
  position: absolute;
  left: 100px;
  top: 0;
}

.fh5co-loader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  /* background: url('../../../public/images/hourglass.svg') center no-repeat
    #222222; */
}

/*# sourceMappingURL=style.css.map */

.asdasdas {
  padding-right: 0px !important;
  padding-left: 0px !important;
  /* border-style: solid;
  border-width: 1px;
  border-color: #eff0f6;
  border-radius: 24px;
  background-color: #fff;
  box-shadow: 0 2px 7px 0 rgb(20 20 43 / 6%); */
}

.fa-phone:before {
  font-size: 75px;
}
.fa-facebook:before {
  font-size: 75px;
}
.fa-instagram:before {
  font-size: 75px;
}

/* .centerrr{
  display: flex;
  justify-content: center;
} */

.spacee {
  display: flex;
  justify-content: center;
  margin-bottom: 5%;
  flex-direction: column;
  align-items: center;
}

#amanda {
  background-image: url('../../../public/images/amanda.jpg');
  background-size: cover;
  background-position: center;
}
#lisa {
  background-image: url('../../../public/images/LisaDall-1.jpg');
  background-size: cover;
  background-position: center;
}

#paula {
  background-image: url('../../../public/images/pualaNew.jpg');
  background-size: cover;
  background-position: center;
}

#guy {
  background-image: url('../../../public/images/guy.jpg');
  background-size: cover;
  background-position: center;
}

.overMe {
  height: 100%;
}
